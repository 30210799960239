import { styled } from '@mui/material/styles';

export const StyledLister = styled('ol')`
    > li > * {
        height: 100%;
        margin: 0 auto;
        box-shadow: none;
    }

    display: grid;
    grid-auto-rows: 1fr;
    gap: 1.9rem;
    margin-top: 0;
    padding: 2rem 3rem 3rem 3rem;
    list-style: none;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        grid-template-columns: 1fr 1fr;
        gap: 2.4rem;
        max-width: 112.4rem;
        margin: 0 auto 4rem auto;
        > li > * {
            min-height: 14rem;
            margin: 0;
        }
    }
`;
