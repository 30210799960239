import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { TextInput } from '../Forms/TextInput';

export const StyledWrapper = styled('div')<{ $errorMessage?: string }>`
    display: block;
    overflow: hidden;
    border-color: ${palette.common.blue};
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 2.1rem;
`;

export const StyledTextInput = styled(TextInput)`
    input {
        margin-left: 0.4rem;
    }
`;

export const StyledHr = styled('div')`
    margin-right: 3.5rem;
    margin-left: 4rem;
    border-bottom: ${`0.1rem solid ${palette.grey[200]};`};
`;

export const StyledErrorMessage = styled('span')`
    display: inline-block;
    margin-top: 0.5rem;
    color: ${palette.error.main};
    text-transform: lowercase;
`;
