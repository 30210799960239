export enum PromoCodeVariant {
    NAVY = 'navy',
    GREY = 'grey',
}

export enum PromoCodeSize {
    SMALL = 'small',
    LARGE = 'large',
}

export type PromoCodeProperties = {
    onClick: () => void;
    promoCode: string;
    size?: PromoCodeSize;
    variant?: PromoCodeVariant;
};
