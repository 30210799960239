import { styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';

import { containerSmallWidth, containerLargeWidth, Theme } from '../../theme';
import { StyledReactMarkdownProperties } from './RichTextContent.types';

// This is a gutterBottom workaround for reactMarkDown.
// We only allow gutterBottom on paragraphs for children of the root rich text element.
// All other Paragraphs contain no margin-bottom
export const StyledReactMarkdown = styled(ReactMarkdown, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledReactMarkdownProperties>`
    max-width: ${({ $fullWidth }: { $fullWidth: boolean }) =>
        $fullWidth ? 'none' : containerSmallWidth};
    margin: 0 auto;
    padding: ${({ $fullWidth }) => ($fullWidth ? '0' : '0 3rem')};
    font-size: 1.6rem;
    line-height: 2.4rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 0;
        font-size: 1.8rem;
        line-height: 2.6rem;
        text-align: ${({ $alignOnDesktop }) => $alignOnDesktop || 'inherit'};
    }

    > p {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        line-height: 2.4rem;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            margin-bottom: 2rem;
            font-size: 1.8rem;
            line-height: 2.6rem;
        }
    }
`;

export const StyledContainer = styled('div')<StyledReactMarkdownProperties>`
    max-width: ${({ $fullWidth }: { $fullWidth: boolean }) =>
        $fullWidth ? 'none' : containerLargeWidth};
    margin: 0 auto;
`;
