import { ReactElement } from 'react';

import { EventType } from '@tgg/services';

export enum ScrollDirection {
    None,
    Left,
    Right,
    Both,
}

export interface ScrollDirectionProperties {
    $direction: ScrollDirection;
}

export interface SimpleCarouselProperties {
    /**
     * The ID attribute for the component.
     */
    id?: string;

    /**
     * map of components that are to be displayed
     */
    galleryElements: ReactElement[];

    /**
     * Used to allow extending components to override the styles in this component.
     */
    className?: string;

    analyticsEvent?: EventType;
}
