import { useMediaQuery } from '@mui/material';
import { useCallback } from 'react';

import { palette, theme } from '../../theme';
import { GooglePlaceRating } from '../GooglePlaceRating';
import {
    StyledAddressContainer,
    StyledAddressLink,
    StyledContainer,
    StyledContainerInner,
    StyledCtaLink,
    StyledHeading,
    StyledIcon,
    StyledJoinContainer,
    StyledJoinDescription,
    StyledJoinNowButton,
    StyledOpeningHoursContainer,
    StyledOpeningHoursIcon,
    StyledOpeningHoursLink,
    StyledOpeningHoursText,
    StyledPrice,
    StyledRegisterText,
    StyledStarDescription,
    StyledStarsImage,
    StyledTrustpilotContainer,
    StyledTrustPilotLink,
} from './GymHeader.styled';
import { GymHeaderProperties } from './GymHeader.types';
import { EventKey } from '@tgg/services';
import {
    openingHoursAsString,
    addressAsString,
    decimalToPoundsAndPence,
    isOpen24Hours,
} from '@tgg/util';

/**
 * The `Gym Header` shows key information about a specific gym.
 *
 * This includes the name of the gym, the global Gym Group Trustpilot rating, the gym opening hours and the primary CTA to encourage users to join the gym.
 */
export function GymHeader({
    id,
    gymName,
    branchId,
    variant = 'join',
    trustpilotData,
    address,
    latitude,
    longitude,
    openingHours,
    fromPrice,
    gymRating,
    isGoogleRatingEnabled,
}: GymHeaderProperties) {
    const iconSize = useMediaQuery(theme.breakpoints.up('desktop')) ? 22 : 15;

    const ctaHref =
        variant === 'join' ? '/join-now/choose-membership/' : `/prospect/`;
    const ctaText = variant === 'join' ? 'Join Now' : 'Register Now';

    const CtaButton = useCallback(
        ({ children }: any) => (
            <StyledCtaLink data-testid={`external${ctaHref}`} href={ctaHref}>
                {children}
            </StyledCtaLink>
        ),
        [ctaHref],
    );

    return (
        <StyledContainer id={id}>
            <StyledContainerInner>
                <div>
                    <StyledHeading
                        variant="h2"
                        component="p"
                        color={palette.common.blue}
                        gutterBottom={false}
                    >
                        {gymName}
                    </StyledHeading>
                    {trustpilotData && (
                        <StyledTrustpilotContainer>
                            <StyledTrustPilotLink
                                href="https://uk.trustpilot.com/review/www.thegymgroup.com"
                                target="_blank"
                            >
                                <StyledStarsImage
                                    src={trustpilotData.starsImageUrl}
                                    alt={`${trustpilotData.starRatingNumber} stars`}
                                />
                            </StyledTrustPilotLink>
                            <StyledStarDescription>
                                <StyledTrustPilotLink
                                    href="https://uk.trustpilot.com/review/www.thegymgroup.com"
                                    target="_blank"
                                    $decorate
                                >
                                    {trustpilotData.starLabel} •{' '}
                                    {new Intl.NumberFormat().format(
                                        trustpilotData.totalNumberOfReviews,
                                    )}{' '}
                                    Reviews
                                </StyledTrustPilotLink>
                            </StyledStarDescription>
                        </StyledTrustpilotContainer>
                    )}
                    {isGoogleRatingEnabled && gymRating && (
                        <GooglePlaceRating
                            rating={gymRating.rating}
                            url={gymRating.url}
                            userRatingsTotal={gymRating.userRatingsTotal}
                        />
                    )}
                    <StyledOpeningHoursContainer>
                        <StyledIcon
                            name="openingHours"
                            color={palette.primary.main}
                            size={iconSize}
                        />
                        <StyledOpeningHoursText>
                            {variant === 'register' && 'Currently closed'}
                            {variant === 'join' &&
                                openingHoursAsString(openingHours)}
                            {!isOpen24Hours(openingHours) && (
                                <>
                                    {' '}
                                    •{' '}
                                    <StyledOpeningHoursLink href="#openingHours">
                                        Full details{' '}
                                        <StyledOpeningHoursIcon
                                            size={14}
                                            color={palette.primary.main}
                                            name="chevronDown"
                                        />
                                    </StyledOpeningHoursLink>
                                </>
                            )}
                        </StyledOpeningHoursText>
                    </StyledOpeningHoursContainer>
                    <StyledAddressContainer>
                        <StyledIcon
                            name="gymLocation"
                            color={palette.primary.main}
                            size={iconSize}
                        />
                        <StyledAddressLink
                            href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {addressAsString(address)}
                        </StyledAddressLink>
                    </StyledAddressContainer>
                </div>
                {fromPrice !== null && (
                    <StyledJoinContainer $variant={variant}>
                        <StyledJoinDescription $variant={variant}>
                            {variant === 'join' ? (
                                <>
                                    from{' '}
                                    <StyledPrice variant="h1" component="span">
                                        {decimalToPoundsAndPence(fromPrice)}
                                    </StyledPrice>{' '}
                                    per month
                                </>
                            ) : (
                                <StyledRegisterText>
                                    Register now and we&apos;ll keep you updated
                                </StyledRegisterText>
                            )}
                        </StyledJoinDescription>
                        {variant === 'register' && (
                            <CtaButton>
                                <StyledJoinNowButton
                                    data-testid="gymHeaderCta"
                                    data-analytics-event={
                                        EventKey.GYM_PAGE_JOIN_NOW
                                    }
                                    data-analytics-payload={JSON.stringify({
                                        ctaPosition: 'gymHeader',
                                        joinNowGym: gymName,
                                    })}
                                    text={ctaText}
                                    fullWidth={false}
                                />
                            </CtaButton>
                        )}
                    </StyledJoinContainer>
                )}
            </StyledContainerInner>
        </StyledContainer>
    );
}
