const text = {
    hiText: `Hi, `,
    instructionalText: 'Tap the icon to view your PIN',
    membershipText: 'membership',
    dateTitle: {
        endDateText: 'End date',
        dayPassText: 'Day Pass',
        guestPassText: 'Day Guest Pass',
        singleDay: '1',
    },
};

export default text;
