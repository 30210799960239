import {
    type objectPosition,
    objectPositionDevices,
    objectPositionVariant,
} from '@tgg/common-types';

const objectPositionValues = {
    [objectPositionVariant.TOP]: 0,
    [objectPositionVariant.LEFT]: 0,
    [objectPositionVariant.TOP_TO_CENTER]: 25,
    [objectPositionVariant.LEFT_TO_CENTER]: 25,
    [objectPositionVariant.CENTER]: 50,
    [objectPositionVariant.CENTER_TO_BOTTOM]: 75,
    [objectPositionVariant.CENTER_TO_RIGHT]: 75,
    [objectPositionVariant.RIGHT]: 100,
    [objectPositionVariant.BOTTOM]: 100,
};

export const getObjectPosition = (
    objectPosition: objectPosition,
    breakpoint: objectPositionDevices,
) => {
    const {
        yCoordinate,
        xCoordinate,
    }: {
        yCoordinate?: objectPositionVariant;
        xCoordinate?: objectPositionVariant;
    } = objectPosition[breakpoint] || {};

    if (yCoordinate || xCoordinate) {
        const verticalObjectPosition =
            objectPositionValues[yCoordinate || objectPositionVariant.CENTER];
        const horizontalObjectPosition =
            objectPositionValues[xCoordinate || objectPositionVariant.CENTER];

        return `${horizontalObjectPosition}% ${verticalObjectPosition}%`;
    }

    return '';
};
