import { useCallback, useEffect, useRef, useState } from 'react';

import {
    StyledLi,
    StyledHeading,
    StyledUl,
    Wrapper,
} from './FilteredListOfGyms.styled';
import { FilteredListOfGymsProperties } from './FilteredListOfGyms.types';
import { Gym, SummarisedBranch } from '@tgg/services';

const renderGymNameWithSearchedTermInBold = (
    gymName: string,
    searchedTermToBeBolded: string,
) => {
    if (!searchedTermToBeBolded) {
        return gymName;
    }
    const nGymName = gymName.toUpperCase();
    const nSearchedTermToBeBolded = searchedTermToBeBolded.toUpperCase();
    const indexOfSearchedTerm = nGymName.indexOf(nSearchedTermToBeBolded);
    const nonBoldedFirstPartOfString = gymName.slice(0, indexOfSearchedTerm);
    const nonBoldedSecondPartOfString = gymName.slice(
        indexOfSearchedTerm + nSearchedTermToBeBolded.length,
        nGymName.length,
    );
    const lastIndexOfSearchedTerm =
        indexOfSearchedTerm + searchedTermToBeBolded.length;

    return (
        <span>
            {nonBoldedFirstPartOfString}
            <strong>
                {gymName.slice(indexOfSearchedTerm, lastIndexOfSearchedTerm)}
            </strong>
            {nonBoldedSecondPartOfString}
        </span>
    );
};

export const FilteredListOfGyms = ({
    onSubmit,
    filteredListOfGyms,
    searchedTerm,
    instructionTip,
    inputReference,
}: FilteredListOfGymsProperties) => {
    const listItemReferences = useRef<(HTMLLIElement | null)[]>([]);
    const [focusedGymIndex, setFocusedGymIndex] = useState(-1);

    const handleSubmit = useCallback(
        (gym: Gym | SummarisedBranch) => {
            setFocusedGymIndex(0);
            onSubmit(gym);
        },
        [onSubmit],
    );

    useEffect(() => {
        setFocusedGymIndex(-1);
    }, [filteredListOfGyms]);

    useEffect(() => {
        const handleKeyDownEvent = (event_: KeyboardEvent) => {
            const { key } = event_;

            if (key !== 'ArrowUp' && key !== 'ArrowDown' && key !== 'Enter') {
                return;
            }

            const nextIndex = Math.min(
                focusedGymIndex + 1,
                filteredListOfGyms.length - 1,
            );
            const previousIndex = Math.max(focusedGymIndex - 1, 0);

            // eslint-disable-next-line default-case
            switch (key) {
                case 'ArrowUp':
                    event_.preventDefault();
                    setFocusedGymIndex(previousIndex);
                    listItemReferences.current[previousIndex]?.focus();
                    break;

                case 'ArrowDown':
                    event_.preventDefault();
                    setFocusedGymIndex(nextIndex);
                    listItemReferences.current[nextIndex]?.focus();
                    break;

                case 'Enter':
                    if (
                        document.activeElement ===
                        listItemReferences.current[focusedGymIndex]
                    ) {
                        handleSubmit(filteredListOfGyms[focusedGymIndex]);
                    }
                    if (
                        document.activeElement === inputReference?.current &&
                        filteredListOfGyms.length === 1
                    ) {
                        handleSubmit(filteredListOfGyms[0]);
                    }
                    break;
            }
        };

        document.addEventListener('keydown', handleKeyDownEvent);

        return () => {
            document.removeEventListener('keydown', handleKeyDownEvent);
        };
    }, [filteredListOfGyms, focusedGymIndex, handleSubmit, inputReference]);

    return (
        <Wrapper>
            <StyledHeading $instructionTip={instructionTip}>
                {!searchedTerm ? instructionTip : undefined}
            </StyledHeading>
            <StyledUl
                role="listbox"
                aria-label="List of gyms"
                id="gymLookupListBox"
            >
                {filteredListOfGyms.map((gym, index) => {
                    return (
                        <StyledLi
                            key={gym.BranchId}
                            tabIndex={0}
                            onClick={() => {
                                handleSubmit(gym);
                            }}
                            aria-label={`Gym ${gym.Name}`}
                            role="option"
                            ref={reference_ => {
                                listItemReferences.current[index] = reference_;
                            }}
                            $selected={index === focusedGymIndex}
                        >
                            {renderGymNameWithSearchedTermInBold(
                                gym.Name,
                                searchedTerm,
                            )}
                        </StyledLi>
                    );
                })}
            </StyledUl>
        </Wrapper>
    );
};
