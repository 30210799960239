export const map = {
    // Intentionally omitting "https://thegymgroup.com/libs/ui/src/lib/components/HeaderInfoSnackBar/HeaderInfoSnackBar.schema.json" from this page
    // If added it would display both in the header and on the page itself.
    'https://thegymgroup.com/libs/ui/src/lib/components/BenefitsBar/BenefitsBar.schema.json':
        'BenefitsBar',
    'https://thegymgroup.com/libs/ui/src/lib/components/Button/Button.schema.json':
        'ButtonCms',
    'https://thegymgroup.com/libs/ui/src/lib/components/ButtonStandalone/ButtonStandalone.schema.json':
        'ButtonStandaloneCms',
    'https://thegymgroup.com/libs/ui/src/lib/components/ContactUs/ContactUs.schema.json':
        'ContactUs',
    'https://thegymgroup.com/libs/ui/src/lib/components/ContentTile/ContentTile.schema.json':
        'ContentTile',
    'https://thegymgroup.com/libs/ui/src/lib/components/GoogleMap/GoogleMap.schema.json':
        'GoogleMap',
    'https://thegymgroup.com/libs/ui/src/lib/components/GymHeader/GymHeader.schema.json':
        'GymHeader',
    'https://thegymgroup.com/libs/ui/src/lib/components/GymLocation/GymLocation.schema.json':
        'GymLocation',
    'https://thegymgroup.com/libs/ui/src/lib/components/MultipleImageComponent/MultipleImageComponent.schema.json':
        'MultipleImageComponent',
    'https://thegymgroup.com/libs/ui/src/lib/components/Heading/Heading.schema.json':
        'Heading',
    'https://thegymgroup.com/libs/ui/src/lib/components/HeroWithOverlay/HeroWithOverlay.schema.json':
        'HeroWithOverlay',
    'https://thegymgroup.com/libs/ui/src/lib/components/HighlightsPanel/HighlightsPanel.schema.json':
        'HighlightsPanel',
    'https://thegymgroup.com/libs/ui/src/lib/components/ImageStandalone/ImageStandalone.schema.json':
        'ImageStandalone',
    'https://thegymgroup.com/libs/ui/src/lib/components/ImageStandaloneFixedAspect/ImageStandaloneFixedAspect.schema.json':
        'ImageStandaloneFixedAspect',
    'https://thegymgroup.com/libs/ui/src/lib/components/MediaGallery/MediaGallery.schema.json':
        'MediaGallery',
    'https://thegymgroup.com/libs/ui/src/lib/components/Membership/Membership.schema.json':
        'Membership',
    'https://thegymgroup.com/libs/ui/src/lib/components/Page/Page.schema.json':
        'Page',
    'https://thegymgroup.com/libs/ui/src/lib/components/Paragraph/Paragraph.schema.json':
        'Paragraph',
    'https://thegymgroup.com/libs/ui/src/lib/components/PromoCountdownTile/PromoCountdownTile.schema.json':
        'PromoCountdownTile',
    'https://thegymgroup.com/libs/ui/src/lib/components/PromoSnackBar/PromoSnackBar.schema.json':
        'PromoSnackBar',
    'https://thegymgroup.com/libs/ui/src/lib/components/PromoTile/PromoTile.schema.json':
        'PromoTile',
    'https://thegymgroup.com/libs/ui/src/lib/components/PromoTileLayout/PromoTileLayout.schema.json':
        'PromoTileLayout',
    'https://thegymgroup.com/libs/ui/src/lib/components/PropositionHero/PropositionHero.schema.json':
        'PropositionHero',
    'https://thegymgroup.com/libs/ui/src/lib/components/PropositionThumbnailGroup/PropositionThumbnailGroup.schema.json':
        'PropositionThumbnailGroupCms',
    'https://thegymgroup.com/libs/ui/src/lib/components/RichTextContent/RichTextContent.schema.json':
        'RichTextContent',
    'https://thegymgroup.com/libs/ui/src/lib/components/Spacing/Spacing.schema.json':
        'Spacing',
    'https://thegymgroup.com/libs/ui/src/lib/components/Steps/Steps.schema.json':
        'Steps',
    'https://thegymgroup.com/libs/ui/src/lib/components/StoryBoxGroup/StoryBoxGroup.schema.json':
        'StoryBoxGroup',
    'https://thegymgroup.com/libs/ui/src/lib/components/TableComposition/TableComposition.schema.json':
        'TableComposition',
    'https://thegymgroup.com/libs/ui/src/lib/components/TitleBox/TitleBox.schema.json':
        'TitleBox',
    'https://thegymgroup.com/libs/ui/src/lib/components/TestimonialsGallery/TestimonialsGallery.schema.json':
        'TestimonialsGallery',
    'https://thegymgroup.com/libs/ui/src/lib/components/VideoStandalone/VideoStandalone.schema.json':
        'VideoStandalone',
} as const;

type ValueOf<T> = T[keyof T];
export type CmsComponentName = ValueOf<typeof map>;
