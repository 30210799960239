import { useMediaQuery } from '@mui/material';
import Image from 'next/image';

import { theme } from '../../theme';
import { SnackBar } from '../SnackBar';
import { TitleBox } from '../TitleBox';
import {
    StyledSection,
    StyledContainer,
    StyledHeading,
    StyledChildren,
    StyledImageContainer,
    StyledParagraphSection,
} from './Confirmation.styles';
import { ConfirmationProperties } from './Confirmation.types';
import { relativeUrlWithBasePath } from '@tgg/util';

/**
 * component to be used on all confirmation pages in member-area
 */
export const Confirmation = ({
    titleBoxText,
    snackBarText,
    snackBarType = 'success',
    headingText,
    paragraph1,
    paragraph2,
    children,
    imageSourceDesktop,
    imageSourceMobile,
    snackBarIcon = 'announcements',
    isWideContainer = false,
    noGutterBottom = false,
    ...baseProperties
}: ConfirmationProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const imageSource = isDesktop ? imageSourceDesktop : imageSourceMobile;

    return (
        <div data-testid={baseProperties['data-testid']}>
            {titleBoxText && <TitleBox text={titleBoxText} />}
            <SnackBar
                message={snackBarText}
                barType={snackBarType}
                iconName={snackBarIcon}
            />
            {imageSource && (
                <StyledImageContainer>
                    <Image
                        role="img"
                        src={relativeUrlWithBasePath(imageSource)}
                        alt="two gym-goers have a friendly fist bump"
                        height={isDesktop ? 542 : 192}
                        width={isDesktop ? 1280 : 450}
                        objectFit="cover"
                    />
                </StyledImageContainer>
            )}

            <StyledContainer
                $noGutterBottom={noGutterBottom}
                data-testid="confirmationContainer"
            >
                <StyledSection>
                    {headingText && (
                        <StyledHeading variant="h2" gutterBottom={false}>
                            {headingText}
                        </StyledHeading>
                    )}
                    <StyledParagraphSection>
                        {paragraph1}
                    </StyledParagraphSection>
                    {paragraph2 && (
                        <StyledParagraphSection>
                            {paragraph2}
                        </StyledParagraphSection>
                    )}
                    <StyledChildren
                        data-testid="confirmation-children"
                        $isWider={isWideContainer}
                    >
                        {children}
                    </StyledChildren>
                </StyledSection>
            </StyledContainer>
        </div>
    );
};
