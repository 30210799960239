import React from 'react';

import {
    StyledCircle,
    StyledCount,
    StyledParagraph,
} from './RemainingCountCircle.styled';
import { RemainingCountCircleProperties } from './RemainingCountCircle.types';

export const RemainingCountCircle = ({
    text,
    remaining,
    total,
}: RemainingCountCircleProperties) => {
    return (
        <StyledCircle>
            <StyledCount>{`${remaining}/${total}`}</StyledCount>
            <StyledParagraph gutterBottom={false}>{text}</StyledParagraph>
        </StyledCircle>
    );
};

export default RemainingCountCircle;
