import { v4 as uuidv4 } from 'uuid';

import * as Components from '../components';
import { RenderedComponentProperties } from './CmsContent.types';
import {
    CmsContentSchema,
    CmsContentProperties,
} from '@tgg/micro-services/cms-client';
import {
    CmsComponentName,
    cmsComponentMapper,
} from '@tgg/micro-services/cms-component-mapper';

export function CmsContent({
    schema,
    contextualProps: contextualProperties = {},
}: CmsContentProperties) {
    const { _meta, ...properties } = schema;

    if (!_meta) {
        return null;
    }

    const componentName = cmsComponentMapper(_meta.schema);

    if (!componentName) {
        return null;
    }

    const TopLevelComponent: any =
        Components[
            // eslint-disable-next-line import/namespace
            componentName as Extract<keyof typeof Components, CmsComponentName>
        ];

    const componentSpecificProperties =
        contextualProperties[componentName] || {};
    const genericProperties = contextualProperties.Generic || {};

    const renderCmsContent = (property: CmsContentSchema) => (
        <CmsContent
            schema={property}
            // eslint-disable-next-line no-underscore-dangle, react/destructuring-assignment
            key={uuidv4()}
            contextualProps={contextualProperties}
        />
    );

    const componentProperties: RenderedComponentProperties = Object.fromEntries(
        Object.entries(properties).map(([key, value]) => {
            const valueIsArray = Array.isArray(value);
            if (
                valueIsArray &&
                value.some(
                    arrayValue =>
                        // eslint-disable-next-line no-prototype-builtins
                        arrayValue.hasOwnProperty('_meta') &&
                        // @ts-ignore
                        // eslint-disable-next-line no-underscore-dangle, no-prototype-builtins
                        arrayValue._meta.hasOwnProperty('deliveryId'),
                )
            ) {
                return [
                    key,
                    value.map(content =>
                        renderCmsContent(content as CmsContentSchema),
                    ),
                ];
            }

            if (
                !valueIsArray &&
                // eslint-disable-next-line no-prototype-builtins
                value.hasOwnProperty('_meta') &&
                // TODO: update CmsContentProperties type to match up with RenderedComponentProperties
                // @ts-ignore
                // eslint-disable-next-line no-underscore-dangle, no-prototype-builtins
                value._meta.hasOwnProperty('deliveryId')
            ) {
                return [key, renderCmsContent(value as CmsContentSchema)];
            }

            return [key, value];
        }),
    );

    return (
        <TopLevelComponent
            {...componentSpecificProperties}
            {...genericProperties}
            {...componentProperties}
        />
    );
}
