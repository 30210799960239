import { styled } from '@mui/material/styles';

import { Theme } from '../../theme';
import { Heading } from '../Heading';
import { StoryBoxSize } from '../StoryBox/StoryBox.types';

export const StyledWrapper = styled('div')`
    box-sizing: content-box;
    max-width: 128rem;
    margin: 0 auto 4rem;
    padding: 0 3rem;
`;

export const StyledContent = styled('ul')`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 0;
    list-style: none;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
        gap: 2rem;
    }
`;

export const StyledContentListItem = styled('li')<{
    $size: StoryBoxSize;
}>`
    flex-basis: ${({ $size }) => ($size === 'large' ? '50%' : '33%')};
`;

export const StyledHeading = styled(Heading)`
    && {
        margin-bottom: 2rem;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;

        && {
            margin-bottom: 3rem;
        }
    }
`;
