import { styled } from '@mui/material/styles';

import { theme, palette } from '../../theme';
import { Heading } from '../Heading';

export const VideoContainer = styled('div')`
    .plyr {
        border-radius: 1.6rem;

        .plyr__control--overlaid {
            background: ${palette.primary.main};
        }

        .plyr__controls__item input {
            color: ${palette.primary.main};
        }
    }
`;
export const StyledVideo = styled('video')`
    width: 100%;
`;

export const StyledVideoDescription = styled('div')`
    display: flex;
    justify-content: space-between;
    margin: ${theme.spacing(15)} 0;

    ${theme.breakpoints.up('desktop')} {
        margin: ${theme.spacing(20)} 0 ${theme.spacing(40)};
        padding: 0 ${theme.spacing(40)};
    }
`;

export const StyledTime = styled('span')`
    margin-left: ${theme.spacing(5)};

    ${theme.breakpoints.up('desktop')} {
        margin-left: ${theme.spacing(10)};
    }
`;

export const StyledVideoTime = styled('div')`
    display: flex;
    align-items: center;
`;

export const StyledVideoTitle = styled(Heading)`
    color: ${palette.common.blue};
    font-size: 1.4rem;
    text-transform: lowercase;

    ${theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;
