import { useState, useRef } from 'react';

import { palette } from '../../theme';
import {
    StyledCollapsibleControl,
    StyledChevronIcon,
    StyledButton,
    StyledDivider,
    StyledCollapsible,
} from './PartialCollapsible.styled';
import { PartialCollapsibleProperties } from './PartialCollapsible.types';
import { EventKey, AccordionClickPayload } from '@tgg/services';

export function PartialCollapsible({
    children,
    minHeightMobile = 0,
    minHeightDesktop = 0,
    variant,
    fullWidth = false,
    alignOnDesktop = 'left',
}: PartialCollapsibleProperties) {
    const [isOpen, setIsOpen] = useState(false);
    const contentReference = useRef<HTMLFormElement>(null);
    const analyticsPayload: AccordionClickPayload = {
        accordionContent: contentReference.current?.textContent || '',
    };

    return (
        <>
            <StyledCollapsible
                appear
                in={isOpen}
                $isOpen={isOpen}
                $alignOnDesktop={alignOnDesktop}
                data-testid="collapsible"
                $minHeightMobile={minHeightMobile}
                $minHeightDesktop={minHeightDesktop}
                ref={contentReference}
            >
                {children}
            </StyledCollapsible>
            <StyledCollapsibleControl
                $fullWidth={fullWidth}
                className="collapsible-control"
                data-testid="collapsible-control"
            >
                <StyledDivider $variant={variant} />
                <StyledButton
                    aria-label={isOpen ? 'Hide content' : 'View content'}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    data-analytics-event={EventKey.ACCORDION_CLICK}
                    data-analytics-payload={JSON.stringify(analyticsPayload)}
                    $variant={variant}
                >
                    <StyledChevronIcon
                        $rotated={isOpen}
                        size={[15, 19]}
                        name="chevronRight"
                        color={
                            variant
                                ? palette.common.white
                                : palette.primary.main
                        }
                    />
                </StyledButton>
                <StyledDivider $variant={variant} />
            </StyledCollapsibleControl>
        </>
    );
}

export default PartialCollapsible;
