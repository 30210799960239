import { styled } from '@mui/material/styles';

import { theme, palette } from '../../theme';
import { Paragraph } from '../Paragraph';

export const StyledOuterWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 8.8rem;
    padding-right: 3rem;
    padding-left: 3rem;
    background-color: ${palette.common.white};
    ${theme.breakpoints.up('desktop')} {
        height: 9.8rem;
        padding: 0;
    }
`;

export const StyledInnerWrapper = styled('div')`
    display: flex;
    justify-content: space-around;
    max-width: 95.2rem;
`;

export const StyledContainer = styled('div')`
    display: flex;
    flex-basis: 20%;
    align-items: center;
    justify-content: center;
    & :not(:first-of-type) {
        padding-left: ${theme.spacing(5)};
    }
    ${theme.breakpoints.up('desktop')} {
        flex: 1 0 auto;
        flex-basis: unset;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    padding-left: ${theme.spacing(5)};
    font-size: 1.2rem;
    line-height: 1.4rem;

    ${theme.breakpoints.up(769)} {
        padding-right: ${theme.spacing(35)};
        padding-left: ${theme.spacing(12)};
        font-size: 1.4rem;
        line-height: 1.6rem;
    }
`;
