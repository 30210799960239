import {
    Container,
    Content,
    DotOne,
    DotTwo,
    DotThree,
    DotFour,
} from './Loader.styled';

export const Loader = () => {
    return (
        <>
            <Container />
            <Content>
                <DotOne />
                <DotTwo />
                <DotThree />
                <DotFour />
            </Content>
        </>
    );
};

export default Loader;
