import { GymInfoWindowProperties } from '../GoogleMap.types';

/**
 * The GymInfoWindow component renders Gym related information when selecting a marker on Google Maps.
 * * The `id` given to the Gym Info `button` is used to attach event listeners for the info window,
 * as this component cannot be rendered directly as a React component within the map (so handlers
 * cannot be passed as props).
 */
export function GymInfoWindow({
    gymName,
    gymAddress,
    lowestPrice,
    latitude,
    longitude,
}: GymInfoWindowProperties) {
    return (
        <div className="info-window-container">
            <span
                className="gym-name"
                role="link"
                id="gym-info-window-link"
                tabIndex={0}
            >
                {gymName}
            </span>
            <a
                className="gym-address"
                href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                target="_blank"
                rel="noreferrer"
            >
                {gymAddress}
            </a>
            <span className="gym-lowest-price">
                From £{lowestPrice} per month
            </span>
            <div className="gym-cta-container">
                <button type="button" id="gym-info-window-button">
                    View Gym
                </button>
            </div>
        </div>
    );
}
