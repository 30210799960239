import React from 'react';

import { PageProperties } from './Page.types';

export const Page = ({ content }: PageProperties) => {
    if (!content || content.length === 0) {
        return null;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{content}</>;
};
