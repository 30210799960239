import { useEffect, useMemo, useState } from 'react';

import { palette } from '../../theme';
import { IconName, Icon } from '../Icon';
import {
    StyledVideoDescription,
    StyledTime,
    StyledVideoTime,
    StyledVideo,
    VideoContainer,
    StyledVideoTitle,
} from './VideoStandalone.styles';
import { VideoStandaloneProperties } from './VideoStandalone.types';
import { pushToDataLayer } from '@tgg/services';
import { duration } from '@tgg/util';
import 'plyr/dist/plyr.css';

/**
 * Show a video at the full width of its container.
 */
const getSources = (url: string) => {
    return [
        {
            src: `${url}/mp4_240p`,
            type: 'video/mp4',
            size: 240,
        },
        {
            src: `${url}/mp4_480p`,
            type: 'video/mp4',
            size: 480,
        },
        {
            src: `${url}/mp4_720p`,
            type: 'video/mp4',
            size: 720,
        },
        {
            src: `${url}/webm_240p`,
            type: 'video/webm',
            size: 240,
        },
        {
            src: `${url}/webm_480p`,
            type: 'video/webm',
            size: 480,
        },
        {
            src: `${url}/webm_720p`,
            type: 'video/webm',
            size: 720,
        },
    ];
};

export function VideoStandalone({
    id,
    image,
    video,
    videoTitle,
    showTime = false,
}: VideoStandaloneProperties) {
    const { primary } = palette;
    const [videoTime, setVideoTime] = useState<string>('00:00');
    const poster = useMemo(() => {
        if (!image || typeof image === 'string') {
            return image;
        }

        const { name, endpoint, defaultHost } = image;

        return `https://${defaultHost}/i/${endpoint}/${encodeURIComponent(
            name,
        )}`;
    }, [image]);

    const sources = useMemo(() => {
        if (typeof video === 'string') {
            return [{ src: video }];
        }

        const { name, endpoint, defaultHost } = video;

        return getSources(
            `https://${defaultHost}/v/${endpoint}/${encodeURIComponent(name)}`,
        );
    }, [video]);

    useEffect(() => {
        /* istanbul ignore next */
        const checkPercentage = (
            expectedPercent = 0,
            currentPercent = 0,
            previousPercent = 0,
        ) =>
            previousPercent === expectedPercent &&
            currentPercent > expectedPercent;

        /* istanbul ignore next */
        async function loadPlayer() {
            const videoName = typeof video === 'string' ? '' : video.name;
            const Plyr = (await import('plyr')).default;
            const player = new Plyr('#video-standalone-player', {
                controls: [
                    'play-large',
                    'play',
                    'progress',
                    'current-time',
                    'duration',
                    'mute',
                    'volume',
                    'settings',
                    'fullscreen',
                ],
            });

            player.source = {
                type: 'video',
                poster,
                sources,
            };

            if (showTime) {
                /* istanbul ignore next */
                player.on('canplay', () =>
                    setVideoTime(duration(player.duration)),
                );
            }

            let previousPercent = 0;

            const timeUpdatePush = (videoPercentage: number) => {
                pushToDataLayer({
                    event: 'videoProgress',
                    videoName,
                    videoPercentage,
                    videoDuration: player.duration,
                    videoCurrentTime: player.currentTime,
                });
            };

            player.on('timeupdate', () => {
                // Get percentage at current playback point.
                const currentPercent = Math.ceil(
                    (player.currentTime / player.duration) * 100,
                );

                // This event fires multiple times per second, so would
                // make multiple dataLayer pushes for each percentage.
                // By using the "previousPercent" variable we can make the push
                // once per percentage.
                // eslint-disable-next-line default-case
                switch (true) {
                    case checkPercentage(10, currentPercent, previousPercent):
                    case checkPercentage(25, currentPercent, previousPercent):
                    case checkPercentage(50, currentPercent, previousPercent):
                    case checkPercentage(75, currentPercent, previousPercent):
                    case checkPercentage(90, currentPercent, previousPercent):
                        timeUpdatePush(previousPercent);
                        break;
                }

                previousPercent = currentPercent;
            });

            // On instantiation "canplay" and "canplaythrough" trigger twice, once with 0 duration and then with the correct duration.
            // To prevent multiple dataLayer pushes, don't push when duration is 0.
            // "canplay" and "canplaythrough" also trigger when seeking, which will spam the dataLayer with pushes.
            // We can prevent that by checking "player.duration && !player.currentTime".
            // This will however trigger an additional push if the user seeks then drags back to the start,
            const analyticEvents = [
                {
                    event: 'canplay',
                    handler: () => {
                        if (player.duration && !player.currentTime) {
                            pushToDataLayer({
                                event: 'videoCanPlay',
                                videoName,
                                videoDuration: player.duration,
                            });
                        }
                    },
                },
                {
                    event: 'canplaythrough',
                    handler: () => {
                        if (player.duration && !player.currentTime) {
                            pushToDataLayer({
                                event: 'videoCanPlayThrough',
                                videoName,
                                videoDuration: player.duration,
                            });
                        }
                    },
                },
                {
                    event: 'play',
                    handler: () => {
                        pushToDataLayer({
                            event: 'videoPlay',
                            videoName,
                            videoDuration: player.duration,
                        });
                    },
                },
                {
                    event: 'pause',
                    handler: () => {
                        // "ended" event also triggers the "pause" event.
                        // Check time and don't push "pause" event to the dataLayer if at end of video.
                        if (player.currentTime !== player.duration) {
                            pushToDataLayer({
                                event: 'videoPause',
                                videoName,
                                videoDuration: player.duration,
                                videoCurrentTime: player.currentTime,
                            });
                        }
                    },
                },
                {
                    event: 'ended',
                    handler: () => {
                        pushToDataLayer({
                            event: 'videoComplete',
                            videoName,
                            videoDuration: player.duration,
                        });
                    },
                },
                {
                    event: 'enterfullscreen',
                    handler: () => {
                        pushToDataLayer({
                            event: 'videoFullScreen',
                            videoName,
                            videoDuration: player.duration,
                        });
                    },
                },
            ];

            analyticEvents.map(({ event, handler }) =>
                // @ts-ignore
                player.on(event, handler),
            );
        }

        // eslint-disable-next-line no-void
        void loadPlayer();
    }, [video, poster, sources, showTime]);

    return (
        <div id={id}>
            {
                // We don't require captions for this player. //
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <VideoContainer>
                    <StyledVideo
                        controls
                        playsInline
                        className="js-plyr plyr"
                        id="video-standalone-player"
                    />
                </VideoContainer>
            }
            {videoTitle && (
                <StyledVideoDescription>
                    <StyledVideoTitle variant="h4">
                        {videoTitle}
                    </StyledVideoTitle>
                    {showTime && (
                        <StyledVideoTime>
                            <Icon
                                name={'openingHours' as IconName}
                                color={primary.main}
                                size={15}
                            />
                            <StyledTime>{videoTime}</StyledTime>
                        </StyledVideoTime>
                    )}
                </StyledVideoDescription>
            )}
        </div>
    );
}
