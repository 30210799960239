import { StyledWrapper } from './ContactUs.styled';

export function ContactUs() {
    return (
        <StyledWrapper>
            <iframe
                title="Feedback Form"
                className="freshwidget-embedded-form"
                id="freshwidget-embedded-form"
                src="https://thegymsupport.freshdesk.com/widgets/feedback_widget/new?&amp;widgetType=embedded&amp;formTitle=Contact+Us&amp;submitTitle=Submit&amp;submitThanks=Thank+you+for+your+enquiry.+We+will+get+back+to+you+as+soon+as+possible.&amp;screenshot=No&amp;attachFile=no&amp;searchArea=no"
                scrolling="no"
                height="750px"
                width="100%"
                frameBorder="0"
            />
        </StyledWrapper>
    );
}

export default ContactUs;
