import { Link } from '@mui/material';

import {
    StyledBreadcrumbs,
    StyledList,
    StyledListItem,
} from './Breadcrumbs.styled';
import { BreadcrumbsProperties } from './Breadcrumbs.types';

export function Breadcrumbs({ elements }: BreadcrumbsProperties) {
    return (
        <StyledBreadcrumbs aria-label="breadcrumbs">
            <StyledList>
                <StyledListItem key="home">
                    <Link href="/">home</Link>
                </StyledListItem>
                {elements.slice(0, -1).map(element => (
                    <StyledListItem key={element.label.replace(/\s/g, '')}>
                        <Link href={`/${element.url}/`}>
                            {element.label.toLowerCase()}
                        </Link>
                    </StyledListItem>
                ))}
                <StyledListItem>
                    <span data-testid="breadcrumbs-last-element">
                        {elements.at(-1)?.label.toLowerCase()}
                    </span>
                </StyledListItem>
            </StyledList>
        </StyledBreadcrumbs>
    );
}
