import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { HorizontalRule } from '../HorizontalRule';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';

export const tileWidthMobile = '31.5rem';
export const tileWidthDesktop = '84.6rem';

export const StyledContentContainer = styled('div')`
    margin: 2rem 3rem 3rem;
    text-align: center;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 2.8rem 4rem 3.9rem 4rem;
    }
`;

export const StyledRule = styled(HorizontalRule)`
    margin: 2rem 0 2rem 0;
    text-align: center;
    background-color: #0000001a;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 2.8rem 0 2.9rem 0;
    }
`;

export const StyledWrapper = styled('div')`
    width: ${tileWidthMobile};
    margin: 2.4rem auto;
    border-radius: 1rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: calc(100%);
        max-width: ${tileWidthDesktop};
    }
`;

export const StyledParagraph = styled(Paragraph)`
    display: inline;
`;

export const StyledPromoParagraph = styled(Paragraph)`
    display: inline;
    color: ${palette.common.white};
    vertical-align: middle;
`;

export const StyledDealIcon = styled(Icon)`
    margin: 0.8rem 0.5rem 0.8rem 0;
`;
