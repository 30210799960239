import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';

import {
    StyledCarousel,
    StyledCarouselContainer,
    StyledSlide,
    StyledSlides,
    StyledIcon,
    StyledArrowContainer,
    StyledInnerArrowContainer,
    StyledArrowLeft,
    StyledArrowRight,
    StyledInnerScrollContainer,
    ScrollbarContainer,
    StyledScrollbar,
} from './SimpleCarousel.styled';
import {
    SimpleCarouselProperties,
    ScrollDirection,
} from './SimpleCarousel.types';
import { dispatchScrollEvent } from '@tgg/services';
import { encodeAsBase64, debounce } from '@tgg/util';

export const handleScroll =
    (setScrollDirection: Dispatch<SetStateAction<ScrollDirection>>) =>
    (element: HTMLDivElement | null) => {
        if (element === null) {
            return;
        }

        const maxScrollLeft = element.scrollWidth - element.offsetWidth;

        if (maxScrollLeft === 0) {
            // no scroll needed
            setScrollDirection(ScrollDirection.None);
        } else if (maxScrollLeft <= element.scrollLeft) {
            // we've scrolled all the way right
            setScrollDirection(ScrollDirection.Left);
        } else if (element.scrollLeft === 0) {
            // we've scrolled all the way left
            setScrollDirection(ScrollDirection.Right);
        } else {
            // we're somewhere in the middle
            setScrollDirection(ScrollDirection.Both);
        }
    };

/**
 * Use the `SimpleCarousel` component to display a carousel of identical sub-elements.
 */
export const SimpleCarousel = ({
    id,
    galleryElements,
    className,
    analyticsEvent,
}: SimpleCarouselProperties) => {
    const carouselReference = useRef<HTMLDivElement>(null);
    const wrapperReference = useRef<HTMLDivElement>(null);
    const scrollBarReference = useRef<HTMLDivElement>(null);
    const isScrolling = useRef<boolean>(false);
    const [scrollDirection, setScrollDirection] = useState(
        ScrollDirection.None,
    );
    const [scrollbarWidth, setScrollbarWidth] = useState(0);

    const onScroll = handleScroll(setScrollDirection);
    const debouncedScroll = debounce(onScroll, 100);

    const calculateInnerScrollBarWidth = () => {
        if (
            carouselReference.current &&
            wrapperReference.current &&
            scrollBarReference.current
        ) {
            const innerScrollWidth =
                (carouselReference.current.scrollWidth /
                    wrapperReference.current.clientWidth) *
                scrollBarReference.current.clientWidth;

            setScrollbarWidth(innerScrollWidth);
        }
    };

    useEffect(() => {
        const carouselElement = carouselReference.current;

        onScroll(carouselElement);
        calculateInnerScrollBarWidth();

        window.addEventListener('resize', () => {
            debouncedScroll(carouselElement);
            calculateInnerScrollBarWidth();
        });
    });

    const handleScrollbarScroll = (
        targetElement: HTMLDivElement | null,
        linkedElement: HTMLDivElement | null,
    ) => {
        if (isScrolling.current) return;

        isScrolling.current = true;

        if (targetElement && linkedElement) {
            const ratio =
                targetElement.scrollLeft /
                (targetElement.scrollWidth - targetElement.clientWidth);

            // eslint-disable-next-line no-param-reassign
            linkedElement.scrollLeft =
                ratio * (linkedElement.scrollWidth - linkedElement.clientWidth);
        }

        setTimeout(() => {
            isScrolling.current = false;
        }, 0);
    };

    return (
        <StyledCarouselContainer
            ref={wrapperReference}
            id={id}
            className={className}
            data-testid="scroll-parent"
        >
            <StyledCarousel
                $direction={scrollDirection}
                ref={carouselReference}
                data-analytics-event={analyticsEvent}
                onScroll={event_ => {
                    handleScrollbarScroll(
                        carouselReference.current,
                        scrollBarReference.current,
                    );
                    debouncedScroll(event_.currentTarget);
                    dispatchScrollEvent(event_);
                }}
            >
                <StyledSlides>
                    {galleryElements.map((galleryItem, index) => {
                        return (
                            <StyledSlide
                                key={encodeAsBase64(
                                    `${galleryItem.toString()}${index}`,
                                )}
                            >
                                {galleryItem}
                            </StyledSlide>
                        );
                    })}
                </StyledSlides>
            </StyledCarousel>
            <StyledArrowContainer>
                <StyledInnerArrowContainer>
                    <StyledArrowLeft
                        $direction={scrollDirection}
                        aria-label="scroll left"
                        disableRipple
                        onClick={() => {
                            carouselReference.current?.scrollBy({
                                left:
                                    -carouselReference.current.scrollWidth /
                                    galleryElements.length,
                                behavior: 'smooth',
                            });
                        }}
                    >
                        <StyledIcon name="chevronLeft" />
                    </StyledArrowLeft>
                    <StyledArrowRight
                        $direction={scrollDirection}
                        aria-label="scroll right"
                        disableRipple
                        onClick={() => {
                            carouselReference.current?.scrollBy({
                                left:
                                    carouselReference.current.scrollWidth /
                                    galleryElements.length,
                                behavior: 'smooth',
                            });
                        }}
                    >
                        <StyledIcon name="chevronRight" />
                    </StyledArrowRight>
                </StyledInnerArrowContainer>
            </StyledArrowContainer>
            <ScrollbarContainer>
                <StyledScrollbar
                    ref={scrollBarReference}
                    onScroll={() =>
                        handleScrollbarScroll(
                            scrollBarReference.current,
                            carouselReference.current,
                        )
                    }
                    style={{ height: 14 }}
                >
                    <StyledInnerScrollContainer $width={scrollbarWidth} />
                </StyledScrollbar>
            </ScrollbarContainer>
        </StyledCarouselContainer>
    );
};

export default SimpleCarousel;
