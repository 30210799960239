import { styled } from '@mui/material/styles';

import { Theme } from '../../theme';
import { Heading } from '../Heading';
import { RichTextContent } from '../RichTextContent';

export const StyledWrapper = styled('div')`
    margin: 0 3rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 84.4rem;
        margin: 0 auto;
    }
`;

export const StyledHeading = styled(Heading, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<{ $withSpacing: boolean }>`
    margin-bottom: ${({ $withSpacing }) => ($withSpacing ? '3rem' : 'revert')};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: ${({ $withSpacing }) =>
            $withSpacing ? '6rem' : 'revert'};
    }
`;

export const StyledRichTextContent = styled(RichTextContent)`
    max-width: 100%;

    p {
        font-size: 1.4rem;
        line-height: 2rem;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            font-size: 1.6rem;
            line-height: 2.2rem;
        }
    }
`;
