export interface GymInformation {
    gymName: string;
    branchId: string;
    gymAddress: string;
    lowestPrice: number;
    distance?: number;
    joiningFee: number;
    latitude: number;
    longitude: number;
    gymPageURL: string;
}

export interface CustomMarker extends google.maps.Marker {
    gym?: GymInformation;
}

export interface CustomMarkerOptions extends google.maps.MarkerOptions {
    gym?: Omit<GymInformation, 'latitude' | 'longitude'>;
}

export enum sortGymsByOptions {
    DISTANCE_UP = 'distance=up',
    LOWEST_PRICE_UP = 'lowestPrice=up',
    LOWEST_PRICE_DOWN = 'lowestPrice=down',
}

export type SortDirection = 'up' | 'down';
