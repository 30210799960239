export const height = 364;
export const width = 630;

export const STORY_BOX_SIZES = {
    default: { height, width },
    smallMobile: {
        height,
        width,
    },
    mobile: { height, width },
    desktop: { height, width },
    largeDesktop: {
        height,
        width,
    },
};
