import { addDays, addMonths, format, isSameDay, isSameMonth } from 'date-fns';

export const formatDate = (date?: Date) => {
    if (!date) return '';

    return format(date, 'yyyy-MM-dd');
};

export const disableSpecificDates = (
    date: Date | null,
    disabledDates: (string | undefined)[],
    month1AvailableSlotCount?: number,
    month2AvailableSlotCount?: number,
    isDisabledAccessRequired?: boolean,
) => {
    if (!date) return false;

    const today = new Date();
    const nextMonth = addMonths(today, 1);

    // disable next 7 days for disabled access
    if (isDisabledAccessRequired) {
        for (let index = 0; index < 7; index += 1) {
            if (isSameDay(date, addDays(today, index))) {
                return true;
            }
        }
    }
    // disable current month if there are no available slots
    if (!month1AvailableSlotCount && isSameMonth(date, today)) {
        return true;
    }
    // disable next month if there are no available slots
    if (!month2AvailableSlotCount && isSameMonth(date, nextMonth)) {
        return true;
    }
    // Drop 2: disable already booked dates
    const formattedDate = formatDate(date);
    return disabledDates.includes(formattedDate);
};
