import { useEffect, useState } from 'react';

import { usePromoCodeContext } from '../../contexts';
import { palette } from '../../theme';
import { Icon } from '../Icon';
import { StyledContent, StyledPromoCodeButton } from './PromoCode.styled';
import {
    PromoCodeProperties,
    PromoCodeSize,
    PromoCodeVariant,
} from './PromoCode.types';

export function PromoCode({
    onClick,
    promoCode,
    variant = PromoCodeVariant.GREY,
    size = PromoCodeSize.SMALL,
}: PromoCodeProperties) {
    const { setCode } = usePromoCodeContext();

    const handleClick = async () => {
        await navigator.clipboard.writeText(promoCode);
        // set code to PromoCodeContext
        setCode(promoCode);
        onClick();
        setIsSuccessShown(true);
    };

    const [isSuccessShown, setIsSuccessShown] = useState(false);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;

        if (isSuccessShown) {
            timeout = setTimeout(() => {
                setIsSuccessShown(false);
            }, 3000);
        }

        return () => clearTimeout(timeout);
    }, [isSuccessShown, setIsSuccessShown]);

    return (
        <StyledPromoCodeButton
            onClick={handleClick}
            className={`promo-code-variant-${variant} promo-code-size-${size}`}
            data-testid="promo-code-copy"
            $variant={variant}
            $size={size}
            $isSuccessShown={isSuccessShown}
        >
            <StyledContent
                $isSuccessShown={isSuccessShown}
                data-testid="promo-code-content"
            >
                <span className="promo-code-text-wrapper">
                    <span>{promoCode}</span>
                    <Icon
                        accessibleTitle="copy promo code"
                        color={
                            variant === PromoCodeVariant.GREY
                                ? palette.common.blue
                                : palette.primary.main
                        }
                        name="copyToClipboard"
                        size={size === PromoCodeSize.SMALL ? 16 : 24}
                    />
                </span>
                <span className="promo-code-success-wrapper">
                    <span>CODE COPIED</span>
                    <Icon
                        accessibleTitle="promo code copied"
                        color={palette.success.main}
                        name="tick"
                        size={size === PromoCodeSize.SMALL ? 16 : 24}
                    />
                </span>
            </StyledContent>
        </StyledPromoCodeButton>
    );
}
