import { getMeridiemTimeFromIsoTime } from '../date';
import { AllDaysOpeningHours } from '@tgg/common-types';

const OPEN_24_HOURS_TEXT = 'Open 24 hours';
export const OPEN_24_7_TEXT = '24 hours, 7 days a week';
export const OPEN_NOT_24_7_TEXT = 'Opening hours';

function formatOpeningHours(openingTime: string, closingTime: string): string {
    // Our assumption is that 24 hour opening times will always return like this
    // See comment on this ticket: https://tggitdev.atlassian.net/browse/MAUI-627
    if (openingTime === '00:00:00' && closingTime === '23:59:59') {
        return OPEN_24_HOURS_TEXT;
    }

    return `${getMeridiemTimeFromIsoTime(
        openingTime,
    )} - ${getMeridiemTimeFromIsoTime(closingTime)}`;
}

export function formatAllOpeningHours(
    openingHours: AllDaysOpeningHours,
): { day: string; hours: string }[] {
    return [
        {
            day: 'Monday',
            hours: formatOpeningHours(
                openingHours.Monday.OpeningTime,
                openingHours.Monday.ClosingTime,
            ),
        },
        {
            day: 'Tuesday',
            hours: formatOpeningHours(
                openingHours.Tuesday.OpeningTime,
                openingHours.Tuesday.ClosingTime,
            ),
        },
        {
            day: 'Wednesday',
            hours: formatOpeningHours(
                openingHours.Wednesday.OpeningTime,
                openingHours.Wednesday.ClosingTime,
            ),
        },
        {
            day: 'Thursday',
            hours: formatOpeningHours(
                openingHours.Thursday.OpeningTime,
                openingHours.Thursday.ClosingTime,
            ),
        },
        {
            day: 'Friday',
            hours: formatOpeningHours(
                openingHours.Friday.OpeningTime,
                openingHours.Friday.ClosingTime,
            ),
        },
        {
            day: 'Saturday',
            hours: formatOpeningHours(
                openingHours.Saturday.OpeningTime,
                openingHours.Saturday.ClosingTime,
            ),
        },
        {
            day: 'Sunday',
            hours: formatOpeningHours(
                openingHours.Sunday.OpeningTime,
                openingHours.Sunday.ClosingTime,
            ),
        },
    ];
}

export function isOpen24Hours(openingHours: AllDaysOpeningHours): boolean {
    const formattedOpeningHours = formatAllOpeningHours(openingHours);

    return formattedOpeningHours.every(
        hoursByDay => hoursByDay.hours === OPEN_24_HOURS_TEXT,
    );
}

export function openingHoursAsString(
    openingHours: AllDaysOpeningHours,
): string {
    return isOpen24Hours(openingHours) ? OPEN_24_7_TEXT : OPEN_NOT_24_7_TEXT;
}
