import { styled } from '@mui/material/styles';

import { Theme } from '../../theme';

export const DownloadOurAppSection = styled('section')`
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin: 2rem 0;
        text-align: center;
    }
`;

export const StyledCheckList = styled('ul')`
    margin: 0 0 3rem;
    padding: 0;
    font-weight: normal;
    text-align: left;
    li {
        svg {
            margin-right: 1.1rem;
        }
        margin-bottom: 1rem;
        list-style: none;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin: 0 auto 3rem auto;
    }
`;

export const StyledAppStoreBadgesContainer = styled('div')`
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: -1rem;
    a {
        display: flex;
    }
`;
