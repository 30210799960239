import { palette } from '../../theme';
import { Icon, IconProperties } from '../Icon';

export function IconComplete(properties: IconProperties) {
    return (
        <Icon viewBox="0 0 28 28" {...properties}>
            <g transform="translate(16.000000, 16.000000)">
                <path
                    d="M6,0.5 C7.51878306,0.5 8.89378306,1.11560847 9.8890873,2.1109127 C10.8843915,3.10621694 11.5,4.48121694 11.5,6 C11.5,7.51878306 10.8843915,8.89378306 9.8890873,9.8890873 C8.89378306,10.8843915 7.51878306,11.5 6,11.5 C4.48121694,11.5 3.10621694,10.8843915 2.1109127,9.8890873 C1.11560847,8.89378306 0.5,7.51878306 0.5,6 C0.5,4.48121694 1.11560847,3.10621694 2.1109127,2.1109127 C3.10621694,1.11560847 4.48121694,0.5 6,0.5 Z"
                    id="Oval"
                    fill={palette.secondary.main}
                />
                <g id="icon" transform="translate(1.000000, 1.000000)">
                    <path
                        d="M8.02343426,2.79237288 C8.17657287,2.89105964 8.22071482,3.09520445 8.12202806,3.24834306 C8.11524539,3.25886817 8.10786895,3.26899856 8.09993455,3.27868504 L4.95565581,7.11728765 C4.88764626,7.20031515 4.79341578,7.25061813 4.69391797,7.26490043 C4.57023793,7.29499442 4.43461687,7.26638277 4.33159535,7.17814017 L1.93433364,5.12477692 C1.79951636,5.00929981 1.78383806,4.80639612 1.89931516,4.67157884 L1.92600078,4.64395197 L1.92600078,4.64395197 C2.07827081,4.50409297 2.31102967,4.50005918 2.46805442,4.63455799 L4.56618182,6.43127273 L7.47834962,2.87811849 C7.61184134,2.71514896 7.84635528,2.67825828 8.02343426,2.79237288 Z"
                        id="Tick"
                        stroke={palette.common.blue}
                        strokeWidth="0.5"
                    />
                </g>
            </g>
        </Icon>
    );
}
