import { keyframes, css, styled } from '@mui/material/styles';

import { palette } from '../../theme';

const dotStyles = css`
    position: relative;
    display: flex;
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem;
    border-radius: 100%;
`;

const dotKeyframes = keyframes`
    0% {
        opacity: 1;
    }
    50%,
    100% {
        opacity: 0.4;
    }
`;

export const Container = styled('div')`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    background: ${palette.common.white};
    opacity: 0.3;
`;
export const Content = styled('div')`
    position: relative;
    z-index: 10001;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 31.25rem;
`;

export const DotOne = styled('div')`
    ${dotStyles};
    color: ${palette.primary.main};
    background-color: ${palette.primary.main};
    animation: ${dotKeyframes} 1s infinite linear alternate;
`;
export const DotTwo = styled('div')`
    ${dotStyles};
    color: ${palette.primary.main};
    background-color: ${palette.primary.main};
    animation: ${dotKeyframes} 1s infinite linear alternate;
    animation-delay: 0.3s;
`;
export const DotThree = styled('div')`
    ${dotStyles};
    color: ${palette.primary.main};
    background-color: ${palette.primary.main};
    animation: ${dotKeyframes} 1s infinite linear alternate;
    animation-delay: 0.6s;
`;
export const DotFour = styled('div')`
    ${dotStyles};
    color: ${palette.primary.main};
    background-color: ${palette.primary.main};
    animation: ${dotKeyframes} 1s infinite linear alternate;
    animation-delay: 1s;
`;
