import { ForwardedRef, forwardRef } from 'react';

import { palette } from '../../theme';
import { Icon } from '../Icon';
import { StyledLink } from './LinkWithChevron.styled';
import { LinkWithChevronProperties } from './LinkWithChevron.types';

/**
 * Show a link to a URL with a right chevron next to it.
 *
 * This outputs an `a` element and should be wrapped with a Next.js `Link` component for any internal links.
 */
export const LinkWithChevron = forwardRef(
    (
        { text, href, ...otherProperties }: LinkWithChevronProperties,
        reference: ForwardedRef<HTMLAnchorElement>,
    ) => {
        return (
            <StyledLink href={href} {...otherProperties} ref={reference}>
                {text}
                <Icon
                    name="smallChevron"
                    size={20}
                    color={palette.primary.main}
                />
            </StyledLink>
        );
    },
);

LinkWithChevron.displayName = 'LinkWithChevron';

export default LinkWithChevron;
