import { theme } from '../../theme';

export const heightMobile = 440;
export const widthMobile = 440;

export const heightDesktop = 965;

export const overrideSizes = {
    default: {
        height: heightDesktop,
        width: theme.breakpoints.values.largeDesktop,
    },
    smallMobile: { height: heightMobile, width: widthMobile },
    mobile: {
        height: heightMobile,
        width: theme.breakpoints.values.desktop,
    },
    desktop: {
        height: heightDesktop,
        width: theme.breakpoints.values.largeDesktop,
    },
    largeDesktop: {
        height: heightDesktop,
        width: theme.breakpoints.values.largeDesktop,
    },
};
