import { styled } from '@mui/material/styles';

import { containerLargeWidth, Theme } from '../../theme';
import { Heading } from '../Heading';
import { HorizontalRule } from '../HorizontalRule';
import { Paragraph } from '../Paragraph';
import { Tabs } from '../Tabs';

interface OpeningTimesProperties {
    $iconWidth: number;
}

export const StyledWrapper = styled('div')`
    max-width: 100%;
    margin: 0 auto;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        max-width: ${containerLargeWidth};
    }
`;

export const StyledRichTextContainer = styled('div')`
    padding: 0.5rem 3rem 0.5rem 3.5rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 2.2rem 3rem;
    }

    li {
        margin-bottom: 1rem;
        font-size: 1.4rem;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            margin-bottom: 2rem;
            font-size: 1.6rem;
        }

        p {
            display: inline;
        }
    }

    ul {
        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            text-align: center;
            list-style: inside;
        }
    }
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
    margin-right: 3rem;
    margin-left: 3rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-right: 0;
        margin-left: 0;
    }
`;

export const StyledTimesAndLocationSection = styled('div')`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: space-evenly;
    margin-top: 3.8rem;
    padding-bottom: 3rem;
    text-align: center;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
    }
`;

export const StyledTimesAndLocationChild = styled('div')`
    display: flex;
`;

export const StyledOpeningTimes = styled('div')`
    display: flex;
    flex-direction: column;
    margin-left: 3rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        display: flex;
        flex: 1 0 25%;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        margin-left: 0;
    }
`;

export const StyledSubHeader = styled('div')`
    display: flex;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: span end;
    grid-column-gap: 0.7rem;
    align-items: center;
    margin-bottom: 1rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: column;
    }
`;

export const StyledHeading = styled(Heading)`
    padding-left: 3rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-left: 0;
        text-align: center;
    }
`;

export const StyledSubHeading = styled(Heading)`
    margin-right: 0;
    margin-left: 0;
`;

export const StyledOpen24Hours = styled(Paragraph, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<OpeningTimesProperties>`
    display: flex;
    padding-left: ${({ $iconWidth }) => `${$iconWidth + 11}px`};
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-left: 0;
    }
`;

export const StyledOpeningTimeAndDayWrapper = styled(
    'div',
)<OpeningTimesProperties>`
    display: flex;
    justify-content: flex-start;
    padding-left: ${({ $iconWidth }) => `${$iconWidth + 9}px`};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        justify-content: space-evenly;
        padding-left: 0;
    }
`;

export const StyledOpeningTimeAndDayContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const StyledOpeningTimesDay = styled(Paragraph)`
    display: flex;
    justify-content: flex-start;
    width: 9rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: auto;
    }
`;

export const StyledOpeningTimesTime = styled(Paragraph)`
    padding-left: 0.75rem;
`;

export const StyledLocationContainer = styled('div')`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 1.8rem;
    padding-left: 3rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex: 1 0 25%;
        margin-top: 0;
        padding: 0;
    }
`;

export const StyledLocationAddressContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: start;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        display: block;
    }
`;

export const StyledAddressLine = styled(Paragraph)`
    padding-right: 3rem;
    padding-left: 3rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-right: 0;
        padding-left: 0;
    }
`;

export const StyledDirections = styled('div')`
    display: flex;
    padding-top: 1rem;
    padding-right: 3rem;
    padding-left: 3rem;
    font-size: 1.2rem;
    a {
        font-size: 1.4rem;
    }
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        display: block;
        padding-right: 0;
        padding-left: 0;
        a {
            padding-left: 2rem;
            font-size: 1.6rem;
        }
    }
`;

export const StyledMapsContainer = styled('div')`
    max-width: 64rem;
    margin: 0 auto;
`;

export const StyledStaticMap = styled('img')`
    width: 100%;
`;

export const StyledOffPeakTimesContainer = styled(
    'section',
)<OpeningTimesProperties>`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 1.8rem;
    padding-left: ${({ $iconWidth }) => `${$iconWidth + 9}px`};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex: 1 0 auto;
        align-items: center;
        margin: 0;
        padding-left: 0;
    }
`;

export const StyledOffPeakTimesDay = styled(Paragraph)`
    display: flex;
    width: 9rem;
`;

export const StyledOffPeakTimesTime = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const StyledOffPeakTimesSection = styled('div')`
    display: flex;
    gap: 0.5rem;
    align-items: start;
    justify-content: center;
`;

export const StyledOffPeakTimesWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: start;
    margin-left: 3rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 0;
    }
`;

export const StyledRenderOffPeakTimesContainer = styled('section')`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
`;

export const StyledOffPeakTimesText = styled('p')`
    margin: 0;
`;

export const StyledTabs = styled(Tabs)`
    margin-top: 1rem;
    margin-inline: 1.5rem;
`;
