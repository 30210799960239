import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useMemo, useRef, useState } from 'react';

import { theme } from '../../../theme';
import { getObjectPosition } from '../ImageStandalone.utils';
import { StyledImage } from './Image.styled';
import { ImageProperties } from './Image.types';
import { objectPositionDevices } from '@tgg/common-types';

export function Image({
    id,
    image,
    alt = '',
    height,
    width,
    className,
    lazy = true,
    objectPosition = {},
    alignCenter,
}: ImageProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const imgReference = useRef<HTMLImageElement>(null);

    const [heightParameter, setHeightParameter] = useState(height ?? 0);
    const [widthParameter, setWidthParameter] = useState(width ?? 0);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const DEFAULT_HEIGHT = isDesktop ? 545 : 192;
        const DEFAULT_WIDTH = window.innerWidth;

        setHeightParameter(
            height ||
                (isLoaded && imgReference.current?.offsetHeight) ||
                DEFAULT_HEIGHT,
        );
        setWidthParameter(
            width ||
                (isLoaded && imgReference.current?.offsetWidth) ||
                DEFAULT_WIDTH,
        );
    }, [
        imgReference.current?.offsetHeight,
        imgReference.current?.offsetWidth,
        isLoaded,
        height,
        width,
        isDesktop,
    ]);

    const url = useMemo(() => {
        if (typeof image === 'string') {
            return image;
        }

        /**
         * Prevent Safari from getting "stuck" - without this, the initial request fixes the size of the <img /> to 0 so the
         * request with updated dimensions is not made.
         *
         * Not sure what the underlying cause is or why it only happens for some images.
         */
        if (heightParameter === 0 && widthParameter === 0) {
            return '';
        }
        const { name, endpoint, defaultHost } = image;

        const imgHeight =
            heightParameter === 'auto' ? '' : `&h=${heightParameter}`;
        const imgWidth =
            widthParameter === 'auto' ? '' : `&w=${widthParameter}`;
        const imgScale = imgHeight || imgWidth ? '&sm=c' : '';
        const amplienceUrl = `https://${defaultHost}/i/${endpoint}/${encodeURIComponent(
            name,
        )}?fmt=auto${imgHeight}${imgWidth}${imgScale}&qlt=default&$qlt$&$poi$`;

        return amplienceUrl;
    }, [image, heightParameter, widthParameter]);

    return (
        <StyledImage
            ref={imgReference}
            id={id}
            onLoad={() => setIsLoaded(true)}
            src={url}
            height={height}
            width={width}
            alt={alt}
            alignCenter={alignCenter}
            loading={lazy ? 'lazy' : 'eager'}
            className={className}
            $objectPositionDesktop={getObjectPosition(
                objectPosition,
                objectPositionDevices.DESKTOP,
            )}
            $objectPositionMobile={getObjectPosition(
                objectPosition,
                objectPositionDevices.MOBILE,
            )}
        />
    );
}
