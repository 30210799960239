import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { palette } from '../../theme';
import { FilteredListOfGyms } from './FilteredListOfGyms';
import { StyledHr, StyledTextInput, StyledWrapper } from './GymLookup.styled';
import { GymLookupProperties } from './GymLookup.types';
import { Gym, SummarisedBranch } from '@tgg/services';

/**
 * Use the `GymLookup` component to display a searchable list of gyms.
 */
export const GymLookup = ({
    onSelect,
    onClear,
    listOfGyms,
    resetTrigger,
}: GymLookupProperties) => {
    const wrapperReference = useRef<HTMLDivElement>(null);
    const inputReference = useRef<HTMLInputElement>(null);

    const [shouldShowList, setShouldShowList] = useState(false);
    const [fieldValue, setFieldValue] = useState('');
    const [filteredListOfGyms, setFilteredListOfGyms] = useState(listOfGyms);

    const handleFocus = () => {
        setShouldShowList(true);
    };

    const handleSelect = useCallback(
        (gym: Gym | SummarisedBranch) => {
            onSelect(gym);
            setFieldValue(gym.Name);
            setShouldShowList(false);
        },
        [onSelect],
    );

    const handleClear = useCallback(() => {
        setFieldValue('');
        setFilteredListOfGyms(listOfGyms);
        setShouldShowList(false);
        if (onClear) onClear();
    }, [listOfGyms, onClear]);

    const handleChange = (event_: ChangeEvent<HTMLInputElement>) => {
        const { target } = event_;
        const { value } = target;
        const filteredList = listOfGyms
            .filter((object, index) => {
                return (
                    index ===
                    listOfGyms.findIndex(o => object.BranchId === o.BranchId)
                );
            })
            .filter((option: Gym | SummarisedBranch) =>
                option.Name.toLocaleLowerCase().includes(
                    value.toLocaleLowerCase(),
                ),
            );
        setFilteredListOfGyms(filteredList);
        setFieldValue(value);
        setShouldShowList(true);
    };

    useEffect(() => {
        const checkIfClickedOutside = (event_: MouseEvent) => {
            const wrapperElement = wrapperReference.current;
            if (
                shouldShowList &&
                !wrapperElement?.contains(event_.target as Node)
            ) {
                setShouldShowList(false);
            }
        };

        const handleKeyPress = (event_: KeyboardEvent) => {
            const { key } = event_;
            if (key === 'Escape') {
                handleClear();
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [fieldValue, handleClear, listOfGyms, shouldShowList]);

    useEffect(() => {
        // if resetTrigger value > 0 then reset
        if (resetTrigger) {
            handleClear();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetTrigger]);

    return (
        <StyledWrapper ref={wrapperReference}>
            <div
                role="combobox"
                aria-expanded={shouldShowList ? 'true' : 'false'}
                aria-owns="gymLookupListBox"
                aria-controls="gymLookupListBox"
                aria-haspopup="listbox"
                id="gymLookupListBox"
            >
                <StyledTextInput
                    name="gymlookup"
                    placeholder="enter gym name"
                    variant="transparent"
                    autoComplete="off"
                    iconElementLeft={{ name: 'search', size: 20 }}
                    iconElementRight={
                        fieldValue
                            ? {
                                  name: 'close',
                                  accessibleTitle: 'Clear',
                                  onClick: handleClear,
                                  onKeyDown: handleClear,
                                  color: palette.primary.main,
                                  size: 20,
                              }
                            : undefined
                    }
                    onFocus={handleFocus}
                    onChange={handleChange}
                    value={fieldValue}
                    ref={inputReference}
                />
            </div>
            {shouldShowList && (
                <>
                    <StyledHr />
                    <FilteredListOfGyms
                        searchedTerm={fieldValue}
                        filteredListOfGyms={filteredListOfGyms}
                        onSubmit={handleSelect}
                        instructionTip="Please start typing"
                        inputReference={inputReference}
                    />
                </>
            )}
        </StyledWrapper>
    );
};
