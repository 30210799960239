import useMediaQuery from '@mui/material/useMediaQuery';

import { theme, palette } from '../../theme';
import { Icon } from '../Icon';
import {
    StyledOuterWrapper,
    StyledInnerWrapper,
    StyledParagraph,
    StyledContainer,
} from './BenefitsBar.styled';
import { BenefitsBarProperties } from './BenefitsBar.types';

/**
 * Use the `BenefitsBar` component to display USPs of a gym.
 */
export function BenefitsBar({ benefits, id }: BenefitsBarProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const validatedBenefits = [...benefits.slice(0, isDesktop ? 6 : 4)];

    return (
        <StyledOuterWrapper id={id}>
            <StyledInnerWrapper>
                {validatedBenefits.map(({ description, iconName }) => (
                    <StyledContainer key={description}>
                        <Icon
                            name={iconName}
                            color={palette.primary.main}
                            size={35}
                        />
                        <StyledParagraph gutterBottom={false}>
                            {description}
                        </StyledParagraph>
                    </StyledContainer>
                ))}
            </StyledInnerWrapper>
        </StyledOuterWrapper>
    );
}

export default BenefitsBar;
