/* istanbul ignore file */
import { styled } from '@mui/material/styles';

import { Theme, containerLargeWidth, palette } from '../../theme';
import { ButtonBase } from '../Button';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { GymHeaderVariant } from './GymHeader.types';

export const StyledContainer = styled('div')`
    padding: 1.5rem 3rem;
    color: ${palette.common.blue};
    background-color: ${palette.grey[100]};
`;

export const StyledContainerInner = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
        max-width: ${containerLargeWidth};
        margin: 0 auto;
    }
`;

export const StyledHeading = styled(Heading)`
    margin: 0 0 1rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 2.2rem;
        font-size: 3.2rem;
    }
`;

export const StyledTrustpilotContainer = styled('div')`
    display: flex;
    align-items: center;
`;

export const StyledStarsImage = styled('img')`
    height: 2rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        height: 2.5rem;
    }
`;

export const StyledTrustPilotLink = styled('a')<{
    $decorate?: boolean;
}>`
    display: inline-flex;
    color: ${palette.common.blue};
    text-decoration: ${({ $decorate }) => ($decorate ? 'underline' : 'none')};

    &:hover {
        text-decoration: none;
    }
`;

export const StyledCtaLink = styled('a')`
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
`;

export const StyledStarDescription = styled('div')`
    margin-left: 1rem;
`;

export const StyledJoinContainer = styled('div')<{
    $variant: GymHeaderVariant;
}>`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin: 1rem -3rem -1.5rem;
    color: ${palette.common.blue};
    background-color: ${palette.common.white};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        justify-content: ${({ $variant }) =>
            $variant === 'join' ? 'center' : 'space-between'};
        width: ${({ $variant }) => ($variant === 'join' ? '19rem' : '23.5rem')};
        height: 17.1rem;
        margin: 0 0 0 3rem;
        border-radius: 1.6rem;
    }
`;

export const StyledIcon = styled(Icon)`
    min-width: 1.5rem;
    margin-top: 0.3rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        min-width: 2.2rem;
        margin-top: 0;
    }
`;

export const StyledOpeningHoursContainer = styled('div')`
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 1rem;
    }
`;

export const StyledOpeningHoursText = styled('div')`
    margin-left: 1rem;
`;

export const StyledOpeningHoursLink = styled('a')`
    color: ${palette.common.blue};
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    svg {
        cursor: pointer;
    }
`;

export const StyledOpeningHoursIcon = styled(Icon)`
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
`;

export const StyledAddressContainer = styled('div')`
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
`;

export const StyledAddressLink = styled('a')`
    margin-left: 1rem;
    color: ${palette.common.blue};
    text-decoration: underline;

    &:hover {
        color: ${palette.common.blue};
        text-decoration: none;
    }
`;

export const StyledJoinDescription = styled('div')<{
    $variant: GymHeaderVariant;
}>`
    margin-top: 1.5rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-top: ${({ $variant }) => ($variant === 'join' ? '0' : '1.5rem')};
        text-align: center;
    }
`;

export const StyledPrice = styled(Heading)`
    && {
        margin: 0;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        display: block;
    }
`;

export const StyledRegisterText = styled('div')`
    /* TODO: Add this to the theme as 'intro text CA' */
    font-size: 1.6rem;
    line-height: 1.5rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        display: block;
        width: 19.5rem;
        margin-right: 2rem;
        margin-left: 2rem;
        padding: 1rem 3rem 0;
        line-height: 1.38;
    }
`;

export const StyledJoinNowButton = styled(ButtonBase)`
    display: block;
    align-self: stretch;
    margin: 1rem 3rem 2rem;
    text-align: center;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        align-self: unset;
        justify-self: flex-end;
        margin: 1rem 0 2rem;
    }
`;
