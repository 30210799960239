import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';
import { Heading } from '../Heading';

export const StyledContainer = styled('div')`
    max-width: 52rem;
    margin: 2rem 0;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin: 3rem 0 2rem;
    }
    border: 0.2rem solid ${palette.grey[50]};
    border-radius: 1.5rem;
`;

export const StyledHeadingBg = styled('div')`
    height: 6.5rem;
    background-color: ${palette.secondary.main};
    border-radius: 1.5rem 1.5rem 0 0;
`;

export const StyledHeading = styled(Heading)`
    color: ${palette.common.blue};
    && {
        padding-top: 1.75rem;
        text-align: center;
    }
`;

export const StyledContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 6.5rem);
    padding: 2rem 0;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 3rem 0;
    }
`;

export const StyledImageContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
`;

export const StyledAvatar = styled(Avatar)`
    width: 12rem;
    height: 12rem;

    img {
        object-position: top;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 15rem;
        height: 15rem;
    }
    border: 0.2rem solid ${palette.secondary.main};
`;

export const StyledSecondHeading = styled(Heading)`
    && {
        text-align: center;
    }
`;

export const StyledInfoContainer = styled('div')`
    display: flex;
    flex-direction: column;
`;

export const StyledUnorderedList = styled('ul')`
    margin-top: 0.5rem;
    padding: 0 2rem;
    text-align: center;
    list-style: none;
`;

export const StyledListItem = styled('li')`
    margin-bottom: 1rem;
`;

export const StyledButtonWrapper = styled('div')`
    width: 25rem;
    margin: 0 auto;
    text-align: center;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 13rem;

        button {
            font-size: 1.8rem;
        }
    }
    ${({ theme }: { theme: Theme }) => theme.breakpoints.down(330)} {
        width: 18rem;
    }
`;
