import { TableCell } from '@mui/material';

import { StyledRichTextContent } from './TableCompositionCell.styled';

export type TableCompositionCellProperties = {
    markdown: string;
};

export function TableCompositionCell({
    markdown,
}: TableCompositionCellProperties) {
    return (
        <TableCell>
            <StyledRichTextContent text={markdown} />
        </TableCell>
    );
}
