import { TableHead, TableRow } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import {
    TableCompositionCell,
    TableCompositionCellProperties,
} from '../Cell/TableCompositionCell';

export type TableCompositionHeadProperties = {
    cells: TableCompositionCellProperties[];
};

export function TableCompositionHead({
    cells,
}: TableCompositionHeadProperties) {
    return (
        <TableHead>
            <TableRow>
                {cells.map(cell => (
                    <TableCompositionCell key={uuidv4()} {...cell} />
                ))}
            </TableRow>
        </TableHead>
    );
}
