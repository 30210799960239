/**
 * Here lies functionality that cannot realistically be tested in Jest.
 *
 * Most of the actual rendering of the map is done by the Google Maps scripts, which we have to mock,
 * so things like event listeners, map clicks and bounds changes aren't really testable.
 */

import { CustomMarker, GymInformation } from '@tgg/common-types';

/**
 * Handle `bounds_changed` event - catchall for zoom / pan. On bounds change, update `visibleMarkers` based on the new
 * viewport of the map.
 *
 * @param map Map whose bounds have changed
 * @param markers Markers whose position we want to check
 * @param setVisibleMarkers Callback to be called with the new list of visible markers
 */

interface HandleBoundsChangedArguments {
    map: google.maps.Map;
    markers: CustomMarker[];
    searchLocation: google.maps.LatLng;
    setVisibleMarkers: (markers: GymInformation[]) => void;
}
export const handleBoundsChanged = ({
    map,
    markers,
    searchLocation,
    setVisibleMarkers,
}: HandleBoundsChangedArguments) => {
    const newVisibleMarkers: GymInformation[] = [];
    const bounds = map.getBounds();

    const getMarkerDistance = (position: google.maps.LatLng) => {
        const latLng = new google.maps.LatLng(searchLocation);
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
            latLng,
            position,
        );

        return distance;
    };

    markers.forEach(marker => {
        const position = marker.getPosition();

        if (position) {
            const isInBounds = bounds?.contains(position);
            const rawDistance = getMarkerDistance(position);
            const distanceInMiles = rawDistance / 1609;
            const distance = Math.floor(distanceInMiles * 10) / 10;

            if (isInBounds && marker.gym) {
                const markerInfo = {
                    ...marker.gym,
                    distance,
                };

                newVisibleMarkers.push(markerInfo);
            }
        }
    });

    setVisibleMarkers(newVisibleMarkers);
};

export const closeInfoWindow = (infoWindow?: google.maps.InfoWindow) => {
    infoWindow?.close();
};

export const setZoom = (isDesktop: boolean) => (isDesktop ? 6 : 5);
