import Link from 'next/link';

import { Paragraph } from '../Paragraph';
import {
    StyledFiitLogo,
    StyledFiitSection,
    StyledHeading,
} from './FiitSection.styled';

export interface FiitSectionProperties {
    imageSrc: string;
}

export const FiitSection = ({ imageSrc }: FiitSectionProperties) => {
    return (
        <StyledFiitSection>
            <StyledFiitLogo src={imageSrc} />
            <StyledHeading variant="h3" component="h2">
                Supercharge your fitness
            </StyledHeading>
            <Paragraph>
                Ultimate members, get access to the no. 1 rated fitness app and
                take your training to the next level with interactive workouts
                that pushes you harder.
            </Paragraph>
            <Paragraph>
                FIIT gives you on demand access to hundreds of killer workouts
                and structured training plans – all led by world-class trainers.
            </Paragraph>
            <Paragraph>
                Make the most of your free FIIT premium, using your gym access
                PIN to log in. Click <Link href="/fiit">here</Link> to download.
            </Paragraph>
        </StyledFiitSection>
    );
};
