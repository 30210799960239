import { Position } from './geolocator.types';

export const getUserPosition = (): Promise<Position> => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position: GeolocationPosition) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    resolve(pos);
                },
                () => {
                    reject(new Error('The Geolocation Service failed '));
                },
            );
        } else {
            reject(new Error("Device doesn't have geolocation enabled "));
        }
    });
};
