import { styled } from '@mui/material/styles';

import { palette } from '../../theme';

export const StyledLink = styled('a')`
    color: ${palette.common.blue};
    text-decoration: underline;
    cursor: pointer;

    svg {
        margin-bottom: 0.1rem;
        cursor: pointer;
    }
`;
