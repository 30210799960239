import { ZodType, ZodTypeDef, ZodError } from 'zod';

export const mapZodErrorToStrings = (error: ZodError): string[] => {
    return error.issues.map(issue => {
        const pathString = issue.path.length > 0 ? `${issue.path}: ` : '';

        return `${pathString}${issue.message}`;
    });
};

export const validation = (
    schema: ZodType<any, ZodTypeDef, any>,
    data: unknown,
) => {
    try {
        schema.parse(data);
        return { isValid: true, errors: null };
    } catch (error) {
        /* istanbul ignore next */
        if (error instanceof ZodError) {
            const errorMessages = mapZodErrorToStrings(error);

            return { isValid: false, errors: errorMessages };
        }

        /* istanbul ignore next */
        return { isValid: false, errors: ['An unexpected error occurred'] };
    }
};
