import { TableBody } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { PartialCollapsible } from '../PartialCollapsible';
import { TableCompositionHead } from './Head/TableCompositionHead';
import { TableCompositionRow } from './Row/TableCompositionRow';
import {
    StyledTable,
    StyledWrapper,
    StyledContainer,
    StyledScrollableContainer,
} from './TableComposition.styled';
import { TableCompositionProperties } from './TableComposition.types';

export function TableComposition({
    rows,
    tableHead,
    firstColumnVariant,
    collapsible = true,
}: TableCompositionProperties) {
    const tableContent = (
        <StyledScrollableContainer>
            <StyledTable
                cellSpacing={10}
                cellPadding={10}
                $firstColumnVariant={firstColumnVariant}
            >
                {tableHead && <TableCompositionHead {...tableHead} />}

                <TableBody>
                    {rows.map(row => (
                        <TableCompositionRow key={uuidv4()} {...row} />
                    ))}
                </TableBody>
            </StyledTable>
        </StyledScrollableContainer>
    );
    return (
        <StyledWrapper>
            <StyledContainer>
                {collapsible ? (
                    <PartialCollapsible
                        minHeightDesktop={60}
                        minHeightMobile={30}
                    >
                        {tableContent}
                    </PartialCollapsible>
                ) : (
                    tableContent
                )}
            </StyledContainer>
        </StyledWrapper>
    );
}
