import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useEffect, useMemo } from 'react';

import { ClusterRenderer } from '../GoogleMap.styled';
import { ClustererProperties } from '../GoogleMap.types';

/**
 * Marker clustering means that groups of nearby markers are displayed as numbers until the user zooms in further to see
 * the specific pins.
 *
 * Clusterer wraps the MarkerClusterer class and passes our Map and Marker instances into it. Does not need to render
 * anything as Google Maps handles all of it behind the scenes.
 */
export function Clusterer({ map, markers }: ClustererProperties) {
    const markerClusterer = useMemo(
        () =>
            new MarkerClusterer({
                renderer: new ClusterRenderer(),
            }),
        [],
    );

    useEffect(() => {
        if (map && markerClusterer) {
            markerClusterer.setMap(map);
        }

        return () => {
            markerClusterer.setMap(null);
        };
    }, [map, markerClusterer]);

    useEffect(() => {
        if (markerClusterer && markers.length > 0) {
            markers.forEach(m => {
                markerClusterer.addMarker(m);
            });
        }

        return () => {
            markerClusterer.clearMarkers();
        };
    }, [markers, markerClusterer]);

    return null;
}
