import { styled } from '@mui/material/styles';

import { Theme } from '../../theme';
import { AlertMessage } from '../AlertMessage';
import { ButtonBase } from '../Button';
import { HorizontalRule } from '../HorizontalRule';
import { Paragraph } from '../Paragraph';

export const StyledForm = styled('form')`
    display: flex;
    flex-direction: column;
`;

export const StyledPersonalDetailsWrapper = styled('div')`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-top: 1rem;
    }
`;

export const StyledSmallText = styled(Paragraph)`
    margin: 0;
    padding-top: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: normal;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
    }
`;

export const StyledSubmitButton = styled(ButtonBase)`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        max-width: 24rem;
        font-size: 1.8rem;
    }
`;

export const StyledSubmitButtonWrapper = styled('div')`
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-top: 3rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 4rem;
    }
`;

export const StyledAlertMessage = styled(AlertMessage)`
    margin-bottom: 2rem;
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
    margin: 3rem 0;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 6rem 0;
    }
`;
