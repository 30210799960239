/* istanbul ignore file */
import { styled } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers';

export const StyledDateInputContainer = styled('div')`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 2.5rem;
`;

export const StyledDateInput = styled(MobileDatePicker)`
    margin-bottom: 3rem;
    font-size: 1.6rem;

    fieldset {
        border-style: solid;
        border-width: 0.1rem;
        border-radius: 2.5rem;
    }

    input {
        padding: 1.5rem;
    }

    svg,
    input,
    fieldset {
        cursor: pointer;
    }
`;

export const StyledHelperText = styled('span')`
    font-size: 1.2rem;
    line-height: 1.8rem;
`;
