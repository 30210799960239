import { StyledGroupedUnorderedList } from './GroupedUnorderedList.styled';
import GroupedUnorderedListProperties from './GroupedUnorderedList.types';

export function GroupedUnorderedList({
    children,
    ...properties
}: GroupedUnorderedListProperties) {
    return (
        <StyledGroupedUnorderedList {...properties}>
            {children}
        </StyledGroupedUnorderedList>
    );
}

export default GroupedUnorderedList;
