import { styled } from '@mui/material/styles';

import { palette, Theme } from '../../theme';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

export const StyledPromoSnackBar = styled('div')`
    display: inline-block;
    width: 100%;
    border-radius: 0.8rem;
`;

export const StyledHeadingContent = styled('div')`
    padding-top: 1.2rem;
    text-align: center;
`;

export const StyledParagraph = styled(Paragraph)`
    display: inline;
    margin-right: 1rem;
    margin-left: 0.6rem;

    &:last-child {
        margin-right: 0;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-right: 2rem;
    }
`;

export const StyledLink = styled('a')`
    text-decoration: none;
`;

export const StyledHeading = styled(Heading)`
    display: inline-block;
    width: 2rem;
    color: ${palette.common.blue};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 2.3rem;
    }
`;
