import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
    ControlledBirthDateInput,
    ControlledEmailInput,
    ControlledTextInput,
} from '../ControlledInputs';
import { StyledForm, StyledSubmitButton } from './ForgottenPinForm.styled';
import { ForgottenPinFormProperties } from './ForgottenPinForm.types';
import { postcodeValidator } from '@tgg/form-validation';

export function ForgottenPinForm({
    onSubmit,
    onValidate,
    isButtonDisabled,
}: ForgottenPinFormProperties) {
    const { handleSubmit, control, formState } = useForm({
        mode: 'onBlur',
        defaultValues: {
            postcode: '',
            email: '',
            dob: '',
        },
    });

    const { isValid, errors } = formState;

    const numberOfErrors = Object.keys(errors).length;

    useEffect(() => {
        onValidate(errors);
        // Excluded onValidate from deps as if it triggers a re-render in the parent
        // this effect will also trigger, creating an infinite loop.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfErrors]);
    return (
        <StyledForm
            onSubmit={handleSubmit(onSubmit)}
            aria-label="email address form"
        >
            <ControlledEmailInput
                label="Email address"
                placeholder="enter email address"
                control={control}
            />
            <ControlledBirthDateInput
                control={control}
                showMinAgeMessage={false}
                placeholder="dd / mm / yyyy"
            />
            <ControlledTextInput
                name="postcode"
                label="Postcode"
                control={control}
                rules={{
                    validate: {
                        validator: postcodeValidator,
                    },
                }}
                isRequired
                id="postcode"
                placeholder="enter postcode"
            />
            <StyledSubmitButton
                type="submit"
                text="Retrieve my pin"
                fullWidth
                disabled={!isValid || isButtonDisabled}
            />
        </StyledForm>
    );
}
