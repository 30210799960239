import { IconName } from '../Icon';

export enum PromoTileVIColor {
    Blue = 'Blue',
    Green = 'Green',
    DarkBlue = 'DarkBlue',
    Ultimate = 'Ultimate',
    Standard = 'Standard',
    OffPeak = 'OffPeak',
}

export type PromoTileProperties = {
    /**
     * Determines the color of the promotion tile
     */
    color: PromoTileVIColor;
    /**
     * Title of the promotion
     */
    title: string;
    /**
     * Icon to be displayed next to the title
     */
    iconName: IconName;
    /**
     * Details of the promotion
     */
    details: string;
    /**
     * The Promotion code
     */
    promoCode?: string;
    /**
     * The name of the gym that is sent in the datalayer event
     */
    gymName?: string;
};
