import { FormLabel, Radio } from '@mui/material';
import { styled } from '@mui/material/styles';

import { palette } from '../../../theme';

export const StyledWrapper = styled('div')`
    margin-top: 2rem;
    margin-bottom: -1rem;
`;

export const StyledFormLabel = styled(FormLabel)`
    &.Mui-focused {
        color: ${palette.common.blue};
    }
`;

export const StyledRadio = styled(Radio)`
    span {
        color: ${palette.common.blue};
    }
`;
