import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { Paragraph } from '../Paragraph';

export const StyledCard = styled(Card)`
    position: relative;
    margin-bottom: 0.5rem;
    padding: 1.5rem;
    overflow: visible;
    color: ${palette.common.blue};
    background-color: ${palette.pino.standardAlternate};
    border-radius: ${({ theme }) => theme.spacing(8)};
    box-shadow: none;
`;

export const StyledParagraph = styled(Paragraph)`
    display: flex;
    justify-content: space-between;
`;
