import {
    FormEvent,
    ForwardedRef,
    forwardRef,
    useEffect,
    useState,
} from 'react';

import { palette } from '../../../theme';
import { Icon } from '../../Icon';
import {
    StyledButton,
    StyledClearButton,
    StyledContainerForButtons,
    StyledForm,
    StyledTextInput,
} from './SearchInput.styled';
import { SearchInputProperties } from './SearchInput.types';

export const search =
    (term: string) =>
    (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (term.length === 0) {
            return;
        }

        window.location.href = `/search/?q=${term.replace(' ', '+')}`;
    };

/**
 * Allow users to search the site.
 *
 * This component contains a form and a submit button that will send the search term to the search results page.
 */
export const SearchInput = forwardRef(
    (
        {
            variant = 'white',
            id,
            onSubmit,
            placeholder,
            value,
            className,
        }: SearchInputProperties,
        reference: ForwardedRef<HTMLInputElement>,
    ) => {
        const [searchTerm, setSearchTerm] = useState<string>('');

        useEffect(() => {
            if (value !== undefined) {
                setSearchTerm(value);
            }
        }, [value]);

        const searchHandler = onSubmit
            ? (event: FormEvent) => {
                  event.preventDefault();
                  onSubmit(searchTerm);
              }
            : search(searchTerm);

        return (
            <StyledForm className={className} onSubmit={searchHandler}>
                <StyledTextInput
                    ref={reference}
                    value={searchTerm}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    onChange={event => setSearchTerm(event.target.value)}
                    variant={variant}
                    placeholder={
                        placeholder ?? (variant === 'grey' ? 'search' : '')
                    }
                    id={id}
                />
                <StyledContainerForButtons>
                    {searchTerm && (
                        <StyledClearButton
                            onClick={() => setSearchTerm('')}
                            type="button"
                        >
                            clear
                        </StyledClearButton>
                    )}
                    <StyledButton
                        type="submit"
                        tabIndex={0}
                        aria-label="search"
                    >
                        <Icon
                            name="search"
                            accessibleTitle="search"
                            color={palette.primary.main}
                            size={24}
                        />
                    </StyledButton>
                </StyledContainerForButtons>
            </StyledForm>
        );
    },
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
