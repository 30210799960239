/* istanbul ignore file */
import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';
import { Icon } from '../Icon';

export const StyledGoogleReviewsContainer = styled('div')`
    display: flex;
    align-items: center;
`;

export const StyledRatingStars = styled('div')`
    height: 2.2rem;
    margin-left: 1rem;
`;

export const StyledGoogleReviewsLink = styled('a')<{
    $decorate?: boolean;
}>`
    display: inline-flex;
    color: ${palette.common.blue};
    text-decoration: ${({ $decorate }) => ($decorate ? 'underline' : 'none')};

    &:hover {
        text-decoration: none;
    }
`;

export const StyledGoogleLogo = styled('img')`
    min-width: 1.5rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        min-width: 2.2rem;
        margin-top: 0;
    }
`;

export const StyledIcon = styled(Icon)`
    min-width: 1.5rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        min-width: 2.2rem;
        margin-top: 0;
    }
`;
