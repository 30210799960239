/* eslint-disable react/no-array-index-key */
import useMediaQuery from '@mui/material/useMediaQuery';

import { palette, theme } from '../../theme';
import { Icon, IconName } from '../Icon';
import { LinkWithChevron } from '../LinkWithChevron';
import { RichTextContent } from '../RichTextContent';
import { Spacing, SpacingSize } from '../Spacing';
import {
    StyledAddressLine,
    StyledDirections,
    StyledHorizontalRule,
    StyledLocationContainer,
    StyledLocationAddressContainer,
    StyledOpen24Hours,
    StyledOpeningTimeAndDayContainer,
    StyledOpeningTimeAndDayWrapper,
    StyledOpeningTimesDay,
    StyledOpeningTimesTime,
    StyledOpeningTimes,
    StyledRichTextContainer,
    StyledSubHeader,
    StyledTimesAndLocationSection,
    StyledWrapper,
    StyledHeading,
    StyledSubHeading,
    StyledMapsContainer,
    StyledStaticMap,
    StyledOffPeakTimesContainer,
    StyledOffPeakTimesDay,
    StyledOffPeakTimesTime,
    StyledOffPeakTimesSection,
    StyledOffPeakTimesWrapper,
    StyledOffPeakTimesText,
    StyledTabs,
} from './GymLocation.styled';
import { GymLocationProperties, TransportType } from './GymLocation.types';
import { RenderOffPeakTimes } from './components/RenderOffPeakTimes.component';
import { useGymLocationMap } from './useGymLocation';
import { EventKey } from '@tgg/services';
import {
    formatAllOpeningHours,
    isOpen24Hours,
    OPEN_24_7_TEXT,
    capitalise,
} from '@tgg/util';

const icons: Record<TransportType, IconName> = {
    [TransportType.WALK]: 'person',
    [TransportType.BICYCLE]: 'bicycle',
    [TransportType.CAR]: 'car',
    [TransportType.BUS]: 'bus',
    [TransportType.TUBE]: 'tube',
    [TransportType.TRAM]: 'tram',
    [TransportType.TRAIN]: 'train',
};

/**
 * The `GymLocation` panel shows users how to get to a specific gym and when that gym is open.
 */
export function GymLocation({
    id,
    heading,
    transportLinks,
    openingHours,
    address,
    latitude,
    longitude,
    offPeakTimes,
    showOffPeakTimes,
}: GymLocationProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const iconSize: [number, number] = [22, 42];
    const formattedOpeningHours =
        openingHours && formatAllOpeningHours(openingHours);
    const isFully24Hours = openingHours && isOpen24Hours(openingHours);
    const uniqueId = 'transport-link-options';

    const signedUrl = useGymLocationMap(latitude, longitude, isDesktop);

    return (
        <StyledWrapper id={id ?? 'how-to-find-us'}>
            <StyledHeading variant="h2">{heading}</StyledHeading>
            {signedUrl && (
                <div>
                    <a
                        href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <StyledMapsContainer>
                            <a
                                href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <StyledStaticMap
                                    data-testid="static-map"
                                    alt="Google Maps"
                                    src={signedUrl}
                                />
                            </a>
                        </StyledMapsContainer>
                    </a>
                </div>
            )}

            {transportLinks && transportLinks.length > 0 && (
                <StyledTabs
                    tabGroupAriaLabel="Transport link options"
                    uniqueId={uniqueId}
                    items={transportLinks.map(
                        ({ transportInformation, transportType }, index) => ({
                            iconName: icons[transportType],
                            label: transportType,
                            'data-analytics-event':
                                EventKey.TRANSPORT_SELECTION,
                            'data-analytics-payload': JSON.stringify({
                                transportType,
                            }),
                            content: (
                                <StyledRichTextContainer
                                    role="tabpanel"
                                    tabIndex={0}
                                    aria-labelledby={`tabpanel-${uniqueId}-${index}`}
                                    id={`tabpanel-${uniqueId}-${index}`}
                                >
                                    <RichTextContent
                                        {...transportInformation}
                                    />
                                </StyledRichTextContainer>
                            ),
                        }),
                    )}
                />
            )}
            <StyledHorizontalRule />
            <StyledTimesAndLocationSection id="gym_times_location_section">
                {(formattedOpeningHours || isFully24Hours) && (
                    <StyledOpeningTimes>
                        <StyledSubHeader>
                            <Icon
                                name="openingHours"
                                color={palette.primary.main}
                                size={iconSize}
                            />{' '}
                            {isDesktop && <Spacing size={SpacingSize.Medium} />}
                            <StyledSubHeading
                                variant="h4"
                                gutterBottom={false}
                                id="openingHours"
                                color={palette.common.blue}
                            >
                                Opening Hours
                            </StyledSubHeading>
                        </StyledSubHeader>
                        {isFully24Hours ? (
                            <StyledOpen24Hours
                                gutterBottom={false}
                                $iconWidth={iconSize[0]}
                            >
                                {OPEN_24_7_TEXT}
                            </StyledOpen24Hours>
                        ) : (
                            <StyledOpeningTimeAndDayContainer>
                                {formattedOpeningHours?.map(
                                    ({ day, hours }) => (
                                        <StyledOpeningTimeAndDayWrapper
                                            key={day}
                                            $iconWidth={iconSize[0]}
                                        >
                                            <StyledOpeningTimesDay
                                                id={day}
                                                gutterBottom={false}
                                            >
                                                {day}:
                                            </StyledOpeningTimesDay>
                                            <StyledOpeningTimesTime
                                                aria-labelledby={day}
                                                gutterBottom={false}
                                            >
                                                {hours}
                                            </StyledOpeningTimesTime>
                                        </StyledOpeningTimeAndDayWrapper>
                                    ),
                                )}
                            </StyledOpeningTimeAndDayContainer>
                        )}
                    </StyledOpeningTimes>
                )}
                <StyledOffPeakTimesContainer $iconWidth={iconSize[0]}>
                    {showOffPeakTimes ? (
                        <>
                            <StyledSubHeader>
                                <Icon
                                    name="blank"
                                    color={palette.primary.main}
                                    size={iconSize}
                                />{' '}
                                {isDesktop && (
                                    <Spacing size={SpacingSize.Medium} />
                                )}
                                <StyledSubHeading
                                    variant="h4"
                                    gutterBottom={false}
                                    color={palette.common.blue}
                                >
                                    off peak hours
                                </StyledSubHeading>
                            </StyledSubHeader>
                            {offPeakTimes?.map((times, index) => {
                                const offPeakDatesComponents = Object.entries(
                                    times,
                                ).map(([key, value]) => {
                                    const capitalisedKey = capitalise(key);
                                    return (
                                        <StyledOffPeakTimesSection
                                            key={`${key}${index}`}
                                        >
                                            <StyledOffPeakTimesDay
                                                gutterBottom={false}
                                            >
                                                {capitalisedKey}:
                                            </StyledOffPeakTimesDay>
                                            <StyledOffPeakTimesTime
                                                aria-label={`${key} off peak times`}
                                            >
                                                {value.length > 0 ? (
                                                    value.map(time => (
                                                        <RenderOffPeakTimes
                                                            key={time.period}
                                                            time={time}
                                                        />
                                                    ))
                                                ) : (
                                                    <StyledOffPeakTimesText
                                                        aria-label={`${key} no off peak hours`}
                                                    >
                                                        No off peak hours
                                                    </StyledOffPeakTimesText>
                                                )}
                                            </StyledOffPeakTimesTime>
                                        </StyledOffPeakTimesSection>
                                    );
                                });
                                return (
                                    <StyledOffPeakTimesWrapper key={index}>
                                        {offPeakDatesComponents}
                                    </StyledOffPeakTimesWrapper>
                                );
                            })}
                        </>
                    ) : null}
                </StyledOffPeakTimesContainer>
                {(address || (latitude && longitude)) && (
                    <StyledLocationContainer>
                        <StyledSubHeader>
                            <Icon
                                name="gymLocation"
                                color={palette.primary.main}
                                size={iconSize}
                            />
                            {isDesktop && <Spacing size={SpacingSize.Medium} />}
                            <StyledSubHeading
                                variant="h4"
                                gutterBottom={false}
                                color={palette.common.blue}
                            >
                                Location
                            </StyledSubHeading>
                        </StyledSubHeader>
                        {address && (
                            <StyledLocationAddressContainer>
                                <StyledAddressLine gutterBottom={false}>
                                    {address.address1}
                                </StyledAddressLine>
                                <StyledAddressLine gutterBottom={false}>
                                    {address.address2}
                                </StyledAddressLine>
                                <StyledAddressLine gutterBottom={false}>
                                    {address.address3}
                                </StyledAddressLine>
                                <StyledAddressLine gutterBottom={false}>
                                    {address.city}
                                </StyledAddressLine>
                                <StyledAddressLine gutterBottom={false}>
                                    {address.postcode}
                                </StyledAddressLine>
                            </StyledLocationAddressContainer>
                        )}
                        {latitude && longitude && (
                            <StyledDirections>
                                <LinkWithChevron
                                    href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    text="Get directions"
                                />
                            </StyledDirections>
                        )}
                    </StyledLocationContainer>
                )}
            </StyledTimesAndLocationSection>
        </StyledWrapper>
    );
}

export default GymLocation;
