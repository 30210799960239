/* istanbul ignore file */
import { Table } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';

export const StyledWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;

    .collapsible-control {
        padding: 0 2rem;
    }
`;

export const StyledContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100vw;
`;

export const StyledScrollableContainer = styled('div')`
    max-width: 100vw;
    padding: 0 2rem;
    overflow: auto;

    .MuiCollapse-hidden & {
        overflow: hidden;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('largeDesktop')} {
        .MuiCollapse-entered & {
            overflow: visible;
        }
    }
`;

export const StyledTable = styled(Table, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<{
    $firstColumnVariant: 'slim' | 'wide';
}>`
    position: relative;
    width: auto;
    max-width: 128rem;
    margin: 0 auto;
    table-layout: ${({ $firstColumnVariant }) =>
        `${$firstColumnVariant === 'slim' ? 'auto' : 'fixed'}`};
    border-collapse: separate;
    border-spacing: 0.2rem;

    .MuiCollapse-entered & .MuiTableHead-root {
        ${({ theme }: { theme: Theme }) =>
            theme.breakpoints.up('largeDesktop')} {
            position: sticky;
            top: 7.2rem;
            z-index: 1;
        }
    }

    .MuiTableHead-root .MuiCollapse-hidden {
        ${({ theme }: { theme: Theme }) => theme.breakpoints.down(768)} {
            ::-webkit-scrollbar {
                height: 0;
            }
            scrollbar-width: none;
        }
    }

    p {
        margin: 0;
        color: ${palette.common.blue};
        font-size: 1.4rem;
    }

    .MuiTableCell-head {
        padding: 1rem;
        background-color: ${palette.grey[100]};
        border-radius: 0.8rem 0.8rem 0 0;

        p {
            padding: 0;
            color: ${palette.common.blue};
            font-size: 1.4rem;
            font-family: 'thegymgroupsans-Headline';
        }
    }

    .MuiTableCell-root {
        min-width: 12rem;
        max-width: 12rem;
        padding: 1rem;
        vertical-align: top;
        border: none;
    }

    .MuiTableCell-root:first-of-type {
        background-color: ${palette.grey[100]};
        ${({ $firstColumnVariant }) =>
            `${$firstColumnVariant === 'slim' ? 'min-width: auto' : ''}`};

        p {
            font-family: 'thegymgroupsans-Headline';
        }
    }

    .MuiTableRow-root:nth-of-type(2n + 2) {
        background-color: ${palette.grey[50]};
    }

    .MuiTableRow-root:nth-of-type(odd):last-child .MuiTableCell-root {
        border-bottom: 1px solid ${palette.grey[900]};
    }

    thead th:first-of-type,
    tbody td:first-of-type {
        position: sticky;
        left: -2rem;
    }
`;
