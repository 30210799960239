import { styled } from '@mui/material/styles';

import { palette, Theme } from '../../theme';
import { ButtonBase } from '../Button';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { Tile } from '../Tile';
import {
    StyledBackgroundProperties,
    StyledButtonProperties,
} from './MemberTile.types';

export const StyledBackground = styled('div')<StyledBackgroundProperties>`
    position: relative;
    height: 100%;
    padding: ${({ theme }) => theme.spacing(20)};
    background-color: ${palette.primary.main};
    border-radius: inherit;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: ${({ theme }) => theme.spacing(40)};
    }
`;

export const StyledTile = styled(Tile)`
    margin-bottom: ${({ theme }) => theme.spacing(15)};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: ${({ theme }) => theme.spacing(20)};
    }
`;

export const StyledLogoContainer = styled('div')`
    position: absolute;
    right: 0;
    bottom: 0;
`;

export const StyledHeadingContainer = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacing(15)};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-bottom: ${({ theme }) => theme.spacing(20)};
    }
`;

export const StyledHeading = styled(Heading)`
    max-width: 90%;
    color: ${palette.common.blue};
    ${({ variant }) => variant === 'h4' && 'font-size: 1.9rem'};
    ${({ variant }) => variant === 'h1' && 'font-size: 2.5rem'};

    white-space: nowrap;
    text-overflow: ellipsis;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        max-width: unset;
        ${({ variant }) => variant === 'h4' && 'font-size: 2.1rem'};
        ${({ variant }) => variant === 'h1' && 'font-size: 3.1rem'};
    }
`;

export const StyledSubtitle = styled(Paragraph)`
    padding-right: ${({ theme }) => theme.spacing(5)};
    font-size: 1rem;
    line-height: 1.6rem;
    text-transform: lowercase;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
    }
`;

export const StyledMembershipDescriptionHeading = styled(Heading)`
    color: ${palette.common.blue};
    font-size: 1rem;
    line-height: 1.6rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    font-size: 1rem;
    line-height: 1.6rem;
    text-transform: lowercase;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
    }
`;

export const StyledDateContainer = styled('div')`
    display: flex;
    flex-direction: column;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
        padding-top: ${({ theme }) => theme.spacing(10)};
    }
`;

export const StyledGreeting = styled('span')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    p {
        font-size: 1rem;
        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            font-size: 1.2rem;
        }
    }
`;

export const StyledMembershipType = styled('span')`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const StyledPinContainer = styled('div')`
    margin-bottom: 1rem;
`;
export const StyledPinWrapper = styled('span')`
    display: inline-block;
    min-width: 11rem;
    padding-right: 1rem;
    padding-left: 1rem;
    color: ${palette.common.blue};
    text-align: center;
    vertical-align: middle;
`;

export const HiddenPin = styled('span')`
    font-size: 2.8rem;
    letter-spacing: 0.313rem;
`;

export const StyledPin = styled('span')`
    font-size: 1.7rem;
    letter-spacing: 0.075rem;
`;

export const StyledButton = styled(ButtonBase, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledButtonProperties>`
    margin: 1.5rem 0 0;
    font-size: ${({ $isRenewalVariant }) =>
        $isRenewalVariant ? '1.9rem' : '1.4rem'};
    font-family: ${({ $isRenewalVariant }) =>
        $isRenewalVariant && 'thegymgroupsans-Headline, sans-serif'};
    background-color: ${({ $isRenewalVariant }) =>
        $isRenewalVariant ? palette.secondary.main : 'unset'};
    border: ${({ $isRenewalVariant }) => $isRenewalVariant && 'none'};
    border-color: ${({ theme }) => theme.palette.common.blue};
`;
