import useMediaQuery from '@mui/material/useMediaQuery';
import Link from 'next/link';

import { theme } from '../../../theme';
import { ImageStandalone } from '../../ImageStandalone';
import { LinkWithChevron } from '../../LinkWithChevron';
import {
    StyledContainer,
    StyledHeading,
    StyledImageContainer,
    desktopImageSizes,
    mobileImageSizes,
} from './PropositionThumbnail.styled';
import { PropositionThumbnailProperties } from './PropositionThumbnail.types';

/**
 * Use the `PropositionThumbnail` component to display a proposition image, text and link.
 */
export const PropositionThumbnail = ({
    image,
    text,
    linkText,
    linkUrl,
    linkHidden,
    onClick,
    prioritiseImageLoading = false,
}: PropositionThumbnailProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const [height, width] = isDesktop ? desktopImageSizes : mobileImageSizes;
    const THUMBNAIL_SIZES = {
        default: { height: desktopImageSizes[0], width: desktopImageSizes[1] },
        smallMobile: {
            height: mobileImageSizes[0],
            width: mobileImageSizes[1],
        },
        mobile: { height: mobileImageSizes[0], width: mobileImageSizes[1] },
        desktop: { height: desktopImageSizes[0], width: desktopImageSizes[1] },
        largeDesktop: {
            height: desktopImageSizes[0],
            width: desktopImageSizes[1],
        },
    };

    const ariaLabel =
        linkText === 'Read more' && text ? `Read more about ${text}` : linkText;

    return (
        <StyledContainer>
            <StyledImageContainer>
                <ImageStandalone
                    {...image}
                    {...{ height, width }}
                    maximumMobileImageHeight={height}
                    prioritiseImageLoading={prioritiseImageLoading}
                    overrideSizes={THUMBNAIL_SIZES}
                />
            </StyledImageContainer>
            {text && <StyledHeading>{text}</StyledHeading>}
            {!linkHidden && (
                <Link href={linkUrl} passHref>
                    <LinkWithChevron
                        text={linkText}
                        href={linkUrl}
                        aria-label={ariaLabel}
                        onClick={onClick}
                    />
                </Link>
            )}
        </StyledContainer>
    );
};

export default PropositionThumbnail;
