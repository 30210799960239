import { styled } from '@mui/material/styles';

import { Palette, palette } from '../../theme';
import { Style } from './IconButton.types';

const buttonBackgroundColorMap: Record<Style, string> = {
    primary: palette.primary.main,
    'primary-inverted': palette.common.white,
    secondary: palette.grey[900],
    'secondary-inverted': 'transparent',
};

export const iconColorMap: Record<Style, Palette> = {
    primary: palette.common.white,
    'primary-inverted': palette.primary.main,
    secondary: palette.common.blue,
    'secondary-inverted': palette.common.white,
};

export const borderColorMap: Record<Style, Palette> = {
    primary: palette.common.white,
    'primary-inverted': palette.common.blue,
    secondary: palette.common.blue,
    'secondary-inverted': palette.common.white,
};

export const StyledButton = styled('button')<{ $style: Style }>`
    position: relative;
    width: 4rem;
    height: 4rem;
    background-color: ${({ $style }) => buttonBackgroundColorMap[$style]};
    border: ${({ $style }) =>
        `0.2rem solid ${buttonBackgroundColorMap[$style]}`};
    border-radius: 50%;
    cursor: pointer;

    &::before {
        position: absolute;
        top: -0.2rem;
        left: -0.2rem;
        display: none;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        content: '';
    }

    &:hover {
        &::before {
            display: block;
            background-color: rgba(0, 0, 0, 0.09);
        }
    }

    &:active {
        &::before {
            display: block;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    &:focus {
        outline: ${({ $style }) =>
            `0.2rem solid ${
                $style === 'secondary-inverted'
                    ? palette.common.white
                    : palette.primary.main
            }`};
        outline-offset: 0.2rem;
    }

    &:disabled {
        opacity: 50%;
    }
`;
