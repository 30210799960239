import { ImageStandaloneProperties } from '../ImageStandalone';

export enum Direction {
    None = 'none',
    Left = 'left',
    Right = 'right',
}

export interface MediaGalleryProperties {
    id?: string;
    tiles: Tile[];
    gymName?: string;
}

interface Tile {
    image: ImageStandaloneProperties;
    subtitle?: string;
}
