/* istanbul ignore file */

import { styled } from '@mui/material/styles';

import { AlertMessage } from '../AlertMessage';

export const StyledAlertMessage = styled(AlertMessage)`
    text-align: left;

    ul {
        margin-bottom: 0;
    }
`;

export const StyledBoldText = styled('span')`
    display: block;
    margin-bottom: 2rem;
    font-family: thegymgroupsans-headline;
`;
