/* eslint-disable unicorn/numeric-separators-style  */

import { CustomMarkerOptions } from '@tgg/common-types';

export const markerOptionWithoutCoordinates = {
    position: {
        lat: null,
        lng: null,
    },
    gym: {
        gymName: 'Gym without coordinates',
        gymAddress: 'GymAddress',
        lowestPrice: 17.99,
        branchId: 'abcd-1234',
    },
};

/**
 * List of gym markers mapped from WebAPI endpoint
 */
export const markerOptions = [
    {
        position: {
            lat: 53.398786,
            lng: -2.357247,
        },
        gym: {
            gymName: 'Altrincham',
            gymAddress:
                'Units 2&3 Atlantic Street Broadheath, Greater Manchester, WA14 5FA',
            lowestPrice: 17.99,
            branchId: 'abcd-1234',
        },
    },
    {
        position: {
            lat: 51.150945,
            lng: 0.869004,
        },
        gym: {
            gymName: 'Ashford',
            gymAddress:
                'Unit 1 New Street Retail Park New Street, Kent, TN24 8TN',
            lowestPrice: 16.99,
            branchId: 'abcd-1234',
        },
    },
    {
        position: {
            lat: 51.81571,
            lng: -0.812945,
        },
        gym: {
            gymName: 'Aylesbury',
            gymAddress:
                '6-8 Great Western Street Friars Square Shopping Centre, Aylesbury, HP20 2PU',
            lowestPrice: 15.99,
            branchId: 'abcd-1234',
        },
    },
    {
        position: {
            lat: 53.55306,
            lng: -1.484777,
        },
        gym: {
            gymName: 'Barnsley',
            gymAddress: '33 Peel Parade, South Yorkshire, S70 2RN',
            lowestPrice: 15.99,
            branchId: 'abcd-1234',
        },
    },
    {
        position: {
            lat: 54.11249530889151,
            lng: -3.2351171015882216,
        },
        gym: {
            gymName: 'Barrow-in-Furness',
            gymAddress:
                'The Gym Group Cornerhouse Retail Park\r\nHindpool Road, Barrow-in-Furness\n, LA14 2NE',
            lowestPrice: 10,
            branchId: 'abcd-1234',
        },
    },
    {
        position: {
            lat: 51.58038149172277,
            lng: 0.4440445204514515,
        },
        gym: {
            gymName: 'Basildon',
            gymAddress:
                'Miles Gray Rd Unit E3, Pipps Hill Retail Park, Basildon, SS14 3AF',
            lowestPrice: 26.99,
            branchId: 'abcd-1234',
        },
    },
    {
        position: {
            lat: 51.251112,
            lng: -1.106717,
        },
        gym: {
            gymName: 'Basingstoke',
            gymAddress:
                'Unit 3a, St Michaels Retail Park, Winchester Road, Basingstoke, RG22 4AN',
            lowestPrice: 21.99,
            branchId: 'abcd-1234',
        },
    },
] as CustomMarkerOptions[];
