import { StyledGroupedUnorderedListItem } from './GroupedUnorderedListItem.styled';
import GroupedUnorderedListItemProperties from './GroupedUnorderedListItem.types';

export default function GroupedUnorderedListItem({
    children,
}: GroupedUnorderedListItemProperties) {
    return (
        <StyledGroupedUnorderedListItem>
            {children}
        </StyledGroupedUnorderedListItem>
    );
}
