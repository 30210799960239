import Link from 'next/link';

import { STORY_BOX_SIZES, height, width } from './StoryBox.constants';
import {
    StyledButton,
    StyledButtonContainer,
    StyledContent,
    StyledHeading,
    StyledImageStandalone,
    StyledParagraph,
    StyledPill,
    StyledTagItem,
    StyledTagLink,
    StyledTagsList,
    StyledWrapper,
} from './StoryBox.styled';
import { StoryBoxProperties } from './StoryBox.types';

export const StoryBox = ({
    button,
    image,
    paragraph,
    pill,
    size,
    tags,
    title,
    variant,
}: StoryBoxProperties) => {
    return (
        <StyledWrapper $variant={variant} data-testid="story-box-wrapper">
            {image?.image && (
                <StyledImageStandalone
                    {...{
                        ...image,
                        height,
                        width,
                        overrideSizes: STORY_BOX_SIZES,
                    }}
                    $size={size}
                />
            )}
            <StyledContent>
                {tags && (
                    <StyledTagsList>
                        {tags?.map(tag => (
                            <StyledTagItem key={tag.href}>
                                <StyledTagLink href={tag.href}>
                                    {tag.text}
                                </StyledTagLink>
                            </StyledTagItem>
                        ))}
                    </StyledTagsList>
                )}
                <StyledHeading variant="h3">{title}</StyledHeading>
                <StyledParagraph>{paragraph}</StyledParagraph>
                <StyledButtonContainer>
                    <Link href={button.href}>
                        <StyledButton
                            text={button.text}
                            href={button.href}
                            buttonStyle={
                                variant === 'light blue'
                                    ? 'secondary-blue-white'
                                    : 'secondary'
                            }
                        />
                    </Link>
                </StyledButtonContainer>
                {pill && <StyledPill>{pill}</StyledPill>}
            </StyledContent>
        </StyledWrapper>
    );
};
