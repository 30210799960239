import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { PromoCodeSize, PromoCodeVariant } from './PromoCode.types';

export const StyledPromoCodeButton = styled('button')<{
    $variant?: PromoCodeVariant;
    $size?: PromoCodeSize;
    $isSuccessShown?: boolean;
}>`
    display: inline-block;
    height: ${({ $size }) => ($size === 'small' ? '3.2rem' : '4rem')};
    margin-left: 1rem;
    padding: ${({ $size }) => ($size === 'small' ? '0 1rem' : '0 1.5rem')};
    overflow: hidden;
    color: ${({ $variant }) =>
        $variant === 'navy' ? palette.common.white : palette.common.blue};
    font-size: inherit;
    background-color: ${({ $variant }) =>
        $variant === 'grey' ? palette.grey[50] : palette.common.blue};
    border-style: none;
    border-radius: 1.9rem;
    cursor: pointer;

    &.promo-code-variant-grey:active {
        ${({ $isSuccessShown }) =>
            !$isSuccessShown
                ? `
                    color: ${palette.common.white};
                      background-color: ${palette.common.blue};

                      .promo-code-text-wrapper {
                        g {
                            fill: ${palette.common.white};
                        }
                      }
                  `
                : ''}
    }

    &.promo-code-variant-navy:active {
        ${({ $isSuccessShown }) =>
            !$isSuccessShown
                ? `
                      color: ${palette.common.blue};
                      background-color: ${palette.grey[900]};
                  `
                : ''}
    }

    ${({ $isSuccessShown }) =>
        $isSuccessShown
            ? `
                  background-color: ${palette.common.blue};
                  color: ${palette.common.white};
              `
            : ''};

    svg {
        margin-left: 0.5rem;
    }

    .promo-code-text-wrapper,
    .promo-code-success-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: ${({ $size }) => ($size === 'small' ? '3.2rem' : '4rem')};
    }
`;

export const StyledContent = styled('span')<{
    $isSuccessShown?: boolean;
}>`
    display: flex;
    flex-direction: column;
    transform: translateY(0);
    transition: transform 0.2s ease;

    ${({ $isSuccessShown }) =>
        $isSuccessShown ? 'transform: translateY(-50%)' : ''};
`;
