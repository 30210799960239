export const formDefaultValues = {
    accountName: '',
    sortCode: '',
    accountNumber: '',
};

export const validationErrorMessages = {
    accountNameRequired: 'Enter the name as it appears on your bank account',
    accountNameMinChars: 'Name must be 6 characters or more',
    accountNameMaxChars: 'Name must be 18 characters or less',
    accountNameOnlyLetters: 'Name must be letters only',
    accountNameTrimmed: 'Account name is required',
    accountNumberLength: 'Enter in a valid account number',
    accountNumberOnlyDigits: 'Number must be digits only',
    accountNumberRequired: 'Account number is required',
};
