import { Wrapper } from '@googlemaps/react-wrapper';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';

import { palette, theme } from '../../theme';
import { AlertMessage } from '../AlertMessage';
import { TextInput } from '../Forms';
import { Icon } from '../Icon';
import { Lister } from '../Lister';
import { Paragraph } from '../Paragraph';
import { useGoogleMap } from './GoogleMap.hooks';
import {
    render,
    StyledBackToSearch,
    StyledButtonLink,
    StyledFindMyLocationButton,
    StyledMapContainer,
    StyledRegionalPreference,
    StyledRegionalPreferenceHeading,
    StyledSearchContainer,
    StyledAlertContainer,
    StyledSearchInput,
    TilesWrapper,
    StyledSearchDropdown,
    StyledListerActionsContainer,
} from './GoogleMap.styled';
import { GoogleMapProperties, MapContainerProperties } from './GoogleMap.types';
import { Clusterer } from './components';
import { sortGymsByOptions } from '@tgg/common-types';
import { dispatchEvent, EventKey, FindAGymSortBy } from '@tgg/services';

const selectItems = {
    [sortGymsByOptions.DISTANCE_UP]: 'distance',
    [sortGymsByOptions.LOWEST_PRICE_UP]: 'price lowest to highest',
    [sortGymsByOptions.LOWEST_PRICE_DOWN]: 'price highest to lowest',
};

/**
 * The GoogleMap component renders an instance of Google Maps onto the page with a list of gym markers. Most
 * of the rendering is done by Google Maps itself; our components are mainly containers for the various Maps
 * instances.
 */
export function GoogleMap({
    apiKey,
    markerOptions,
    withSearch = false,
    regionName,
    onGymClick,
    draggable = true,
    disableControlZoom = false,
    draggableCursor,
    initialQuery,
}: GoogleMapProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    return (
        <Wrapper
            apiKey={apiKey}
            render={render}
            libraries={['geometry', 'places']}
        >
            <MapContainer
                markerOptions={markerOptions}
                withSearch={withSearch}
                regionName={regionName}
                onGymClick={onGymClick}
                isDesktop={isDesktop}
                draggable={draggable}
                disableControlZoom={disableControlZoom}
                draggableCursor={draggableCursor}
                initialQuery={initialQuery}
            />
        </Wrapper>
    );
}
export default GoogleMap;

/**
 * MapContainer acts as a bridge between the Wrapper component and its sub components. The Map and Marker instances
 * must be created within the Wrapper component boundary, and then passed down into each of the sub components.
 */
function MapContainer({
    markerOptions,
    withSearch,
    regionName,
    onGymClick,
    isDesktop,
    draggable,
    disableControlZoom,
    draggableCursor,
    initialQuery,
}: MapContainerProperties) {
    const {
        map,
        markers,
        visibleMarkers,
        mapReference,
        searchTerm,
        onSearch,
        haveSearched,
        onClearSearch,
        onFindMyLocation,
        searchInputReference,
        userPositionError,
    } = useGoogleMap(
        markerOptions,
        withSearch,
        isDesktop,
        draggable,
        disableControlZoom,
        draggableCursor,
        onGymClick,
        initialQuery,
    );

    const [sortByValue, setSortByValue] = useState<sortGymsByOptions>(
        sortGymsByOptions.DISTANCE_UP,
    );

    const clearSearchLinkText = 'back to all gyms';

    return (
        <>
            {withSearch && (
                <>
                    <StyledSearchContainer>
                        <StyledSearchInput
                            ref={searchInputReference}
                            onSubmit={onSearch}
                            placeholder="Street, town or postcode"
                            value={searchTerm}
                        />
                        <StyledFindMyLocationButton
                            text="Find my location"
                            buttonStyle="secondary"
                            onClick={onFindMyLocation}
                            startIcon={
                                <Icon
                                    name="targetLocation"
                                    color={palette.primary.main}
                                    size={24}
                                />
                            }
                        />
                    </StyledSearchContainer>
                    {userPositionError && (
                        <StyledAlertContainer>
                            <AlertMessage
                                type="information"
                                text="Sorry there is a problem retrieving your location, please search for a location instead"
                            />
                        </StyledAlertContainer>
                    )}
                </>
            )}
            <StyledMapContainer
                ref={mapReference}
                variant={!withSearch || haveSearched ? 'short' : 'tall'}
            />
            <Clusterer map={map} markers={markers} />
            {withSearch && haveSearched && (
                <>
                    <StyledListerActionsContainer>
                        <StyledBackToSearch>
                            <Icon
                                name="chevronLeft"
                                color={palette.primary.main}
                                size={12}
                            />{' '}
                            <StyledButtonLink
                                onClick={() =>
                                    onClearSearch(clearSearchLinkText)
                                }
                            >
                                {clearSearchLinkText}
                            </StyledButtonLink>
                        </StyledBackToSearch>

                        <StyledSearchDropdown>
                            <TextInput
                                id="sort-by-select"
                                select
                                value={sortByValue}
                                onChange={event => {
                                    const eventValue = event.target
                                        .value as sortGymsByOptions;
                                    setSortByValue(eventValue);
                                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                    dispatchEvent<FindAGymSortBy>(
                                        EventKey.GYM_SORT,
                                        {
                                            sort_by: selectItems[eventValue],
                                        },
                                    );
                                }}
                                iconElementRight={{
                                    name: 'tick',
                                }}
                                label={
                                    <>
                                        <strong>sort by:</strong>{' '}
                                        {selectItems[sortByValue]}
                                    </>
                                }
                            >
                                {Object.entries(selectItems).map(
                                    ([key, value]) => {
                                        return (
                                            <option key={key} value={key}>
                                                {value}
                                            </option>
                                        );
                                    },
                                )}
                            </TextInput>
                        </StyledSearchDropdown>
                    </StyledListerActionsContainer>
                    <TilesWrapper>
                        <Lister
                            gyms={visibleMarkers}
                            sortByValue={sortByValue}
                        />
                    </TilesWrapper>
                </>
            )}
            {!withSearch && regionName && (
                <>
                    <StyledRegionalPreference>
                        <StyledRegionalPreferenceHeading variant="h2">{`our ${regionName}`}</StyledRegionalPreferenceHeading>
                        <Paragraph gutterBottom={false}>
                            Choose your preferred gym to view
                        </Paragraph>
                    </StyledRegionalPreference>
                    <TilesWrapper>
                        <Lister gyms={visibleMarkers} regionName={regionName} />
                    </TilesWrapper>
                </>
            )}
        </>
    );
}
