import { StoryBox } from '../StoryBox/StoryBox';
import {
    StyledContent,
    StyledContentListItem,
    StyledHeading,
    StyledWrapper,
} from './StoryBoxGroup.styled';
import { StoryBoxGroupProperties } from './StoryBoxGroup.types';

export const StoryBoxGroup = ({
    id,
    heading,
    data,
    variant,
    className,
}: StoryBoxGroupProperties) => {
    return (
        <StyledWrapper id={id} className={className}>
            {heading && <StyledHeading variant="h2">{heading}</StyledHeading>}
            <StyledContent className="story-box-list">
                {data.map(item => {
                    const size =
                        item?.size || (data.length < 3 ? 'large' : 'small');

                    return (
                        <StyledContentListItem
                            key={item.title}
                            className="story-box-item"
                            $size={size}
                        >
                            <StoryBox
                                {...item}
                                size={size}
                                variant={variant || item.variant}
                            />
                        </StyledContentListItem>
                    );
                })}
            </StyledContent>
        </StyledWrapper>
    );
};
