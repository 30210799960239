/* istanbul ignore file */
import { palette } from '../../theme';
import { Icon } from '../Icon';
import {
    StyledList,
    StyledListContent,
    StyledHeading,
    StyledHeadingWrapper,
    StyledIconContainer,
    StyledParagraph,
} from './ListWithIcons.styled';
import { ListWithIconsProperties } from './ListWithIcons.types';

export function ListWithIcons({
    title = '',
    titleBold = false,
    iconName = 'tick',
    list,
}: ListWithIconsProperties) {
    return (
        <>
            {title && (
                <StyledHeadingWrapper data-testid="list-title">
                    <StyledHeading $titleBold={titleBold} gutterBottom={false}>
                        {title}
                    </StyledHeading>
                </StyledHeadingWrapper>
            )}
            <StyledList>
                {list.map((item, index) => {
                    const key =
                        typeof item === 'string' ? item : 'non-string-item';
                    return (
                        <StyledListContent
                            // disabling this rule as there is no other unique field other than index.
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${key}${index}`}
                            aria-label="List Item"
                        >
                            <StyledIconContainer>
                                <Icon
                                    name={iconName}
                                    color={palette.primary.main}
                                    size={22}
                                />
                            </StyledIconContainer>

                            <StyledParagraph gutterBottom={false}>
                                {item}
                            </StyledParagraph>
                        </StyledListContent>
                    );
                })}
            </StyledList>
        </>
    );
}

export default ListWithIcons;
