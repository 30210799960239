import {
    StyledGroupedUnorderedListSection,
    StyledHeading,
    StyledChildrenWrapper,
    StyledTitle,
    ListItemWrapper,
} from './GroupedUnorderedListSection.styled';
import GroupedUnorderedListSectionProperties from './GroupedUnorderedListSection.types';

export default function GroupedUnorderedListSection({
    title,
    children,
}: GroupedUnorderedListSectionProperties) {
    return (
        <ListItemWrapper>
            <StyledGroupedUnorderedListSection>
                <StyledHeading>
                    <StyledTitle aria-label={`${title} section`} tabIndex={0}>
                        {title}
                    </StyledTitle>
                </StyledHeading>
                <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
            </StyledGroupedUnorderedListSection>
        </ListItemWrapper>
    );
}
