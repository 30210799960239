import {
    StyledContainer,
    StyledPromoTileLayout,
} from './PromoTileLayout.styled';
import { PromoTileLayoutProperties } from './PromoTileLayout.types';

const MAX_WIDTH = {
    1: 720,
    2: 1080,
    3: 1440,
};

export const PromoTileLayout = ({
    id,
    content,
}: PromoTileLayoutProperties): React.ReactElement => {
    const numberOfChildren = content.length;
    const numberOfColumns = getNumberOfColumns(numberOfChildren);

    return (
        <StyledContainer id={id ?? 'promo-tiles'}>
            <StyledPromoTileLayout
                $numberOfColumns={numberOfColumns}
                $maxWidth={MAX_WIDTH[numberOfColumns]}
                data-testid="promo-tile-layout"
            >
                {content}
            </StyledPromoTileLayout>
        </StyledContainer>
    );
};

export const getNumberOfColumns = (numberOfChildren: number) =>
    numberOfChildren >= 3 && numberOfChildren % 2 !== 0
        ? 3
        : numberOfChildren % 2 === 0
        ? 2
        : 1;
