/* eslint-disable react/no-danger */
import { palette } from '../../theme';
import { Icon } from '../Icon';
import { PartialCollapsible } from '../PartialCollapsible';
import {
    StyledWrapper,
    StyledContainer,
    StyledHeading,
    StyledItem,
    StyledItemTitle,
} from './HighlightsPanel.styled';
import { HighlightsPanelProperties, Highlight } from './HighlightsPanel.types';

const renderItem = ({ itemTitle, description, iconName }: Highlight) => (
    <StyledItem key={itemTitle}>
        <Icon name={iconName} color={palette.secondary.main} size={[26, 42]} />
        <StyledItemTitle variant="h4">{itemTitle}</StyledItemTitle>
        <span
            dangerouslySetInnerHTML={{
                __html: description,
            }}
        />
    </StyledItem>
);

/**
 * The `HighlightsPanel` component is display the key features of a gym.
 *
 * An icon corresponds to a title and description.
 */
export function HighlightsPanel({
    id,
    heading,
    highlights,
}: HighlightsPanelProperties) {
    const firstSixItems = highlights.slice(0, 6);
    const remainderItems = highlights.slice(6);

    return (
        <StyledWrapper id={id}>
            <StyledContainer>
                <StyledHeading variant="h2" color={palette.common.white}>
                    {heading}
                </StyledHeading>
                {firstSixItems.map(item => renderItem(item))}
                {remainderItems.length > 0 && (
                    <PartialCollapsible
                        minHeightDesktop={5}
                        minHeightMobile={3}
                        variant
                    >
                        {remainderItems.map(item => renderItem(item))}
                    </PartialCollapsible>
                )}
            </StyledContainer>
        </StyledWrapper>
    );
}

export default HighlightsPanel;
