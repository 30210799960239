export function duration(time: number): string {
    let seconds = '';
    let minutes = '';
    const truncTime = Math.trunc(time);
    const getMins = Math.floor(truncTime / 60);
    const getSecs = truncTime % 60;

    minutes = getMins >= 10 ? getMins.toString() : `0${getMins}`;
    seconds = getSecs >= 10 ? getSecs.toString() : `0${getSecs}`;

    return `${minutes}:${seconds}`;
}
