import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';
import { ButtonBase } from '../Button';

export const StyledWrapper = styled('div')`
    display: flex;
    gap: 2rem;
    max-width: 134rem;
    margin: 3rem auto;
`;

export const StyledList = styled('ul')`
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    margin: 0;
    padding: 0;
    overflow-x: auto;
    list-style: none;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('mobile')} {
        flex-wrap: wrap;
        overflow-x: visible;
    }

    li:first-of-type {
        padding-left: 1rem;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            padding-left: 3rem;
        }
    }

    li:last-of-type {
        padding-right: 1rem;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            padding-right: 3rem;
        }
    }
`;

export const StyledButton = styled(ButtonBase)`
    height: 4.4rem;
    color: ${palette.common.blue};
    font-size: 1.4rem;
    font-family: 'thegymgroupsans-Bold';
    white-space: nowrap;
    background-color: ${palette.secondary.main};
    border: 0.2rem solid ${palette.common.blue};

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        content: '';
    }

    &&:hover {
        color: ${palette.common.blue};
        background-color: ${palette.secondary.main};

        &::before {
            display: block;
            background-color: rgba(0, 0, 0, 0.09);
        }
    }

    &&:focus {
        color: ${palette.common.blue};
        background-color: ${palette.secondary.main};
        outline-color: ${palette.common.blue};
    }

    &:active {
        &::before {
            display: block;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
`;
