import { styled } from '@mui/material/styles';

import { Theme } from '../../theme';
import { AlertMessage } from '../AlertMessage';
import { ButtonBase } from '../Button';
import { HorizontalRule } from '../HorizontalRule';

export const StyledForm = styled('form')`
    display: flex;
    flex-direction: column;
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
    margin: 3rem 0;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin: 4rem 0;
    }
`;

export const StyledWrapper = styled('div')`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 0 11rem;
        text-align: left;
    }
`;

export const StyledSubmitButton = styled(ButtonBase)`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.8rem;
    }
`;

export const StyledSubmitButtonWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    padding: 3rem 0 0 0;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row-reverse;
        padding: 4rem 19rem 0;
    }
`;

export const StyledSmallText = styled('span')`
    display: block;
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.6;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
    }
`;

export const StyledAlertMessage = styled(AlertMessage)`
    margin-top: 2.5rem;
`;
