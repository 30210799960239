import { styled } from '@mui/material/styles';

import { ImageProperties, StyledImageProperties } from './Image.types';

export const StyledImage = styled('img')<
    StyledImageProperties & Partial<ImageProperties>
>`
    display: block;
    width: ${({ width }) =>
        typeof width === 'number' ? `${width}px` : width || '100%'};
    max-width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: cover;

    ${({ alignCenter }) => {
        if (alignCenter) {
            return `
                display: block;
                margin: 0 auto;
            `;
        }

        return ``;
    }}
`;
