import { StyledOl } from './OrderedList.styled';
import { OrderedListProperties } from './OrderedList.types';

/*
 * Use the `OrderedList` component to display a numbered list of items.
 */
export function OrderedList({ children }: OrderedListProperties) {
    if (!children) {
        return null;
    }

    return <StyledOl>{children}</StyledOl>;
}

export default OrderedList;
