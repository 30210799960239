import { styled } from '@mui/material/styles';

import { StyledImgProperties } from './Picture.types';

export const StyledImg = styled('img')<StyledImgProperties>`
    width: 100%;
    height: ${({ $maximumMobileImageHeight }) =>
        `${$maximumMobileImageHeight}px`};
    object-fit: cover;

    ${({ theme }) => theme.breakpoints.down(440)} {
        object-position: ${({ $objectPositionMobile }) =>
            $objectPositionMobile || 'center'};
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        object-position: ${({ $objectPositionDesktop }) =>
            $objectPositionDesktop || 'center'};
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        height: ${({ $height }) =>
            typeof $height === 'number' ? `${$height}px` : 'auto'};
    }
`;
