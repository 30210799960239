import { styled } from '@mui/material/styles';

import { palette, Theme } from '../../theme';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

export const StyledWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const StyledParagraph = styled(Paragraph)`
    font-size: 1rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 0.5rem;
        font-size: 1.2rem;
    }
`;

export const StyledProgressBar = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 1rem 0 1rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 0 2.5rem 0 2.5rem;
        font-size: 1.2rem;
    }
`;

export const StyledHeading = styled(Heading)`
    color: ${palette.common.blue};
    font-size: 1.5rem;
    transform: translateY(0.2rem);
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 3.1rem;
        transform: translateY(0.4rem);
    }
`;
