import { BasketPaymentSummary } from '@tgg/common-types';

export const mockPaymentSummary: BasketPaymentSummary = {
    today: {
        items: [
            {
                cost: 21.99,
                label: 'Ultimate Monthly',
                originalPrice: 24.99,
                category: 'Monthly membership Fee',
            },
            {
                cost: 0,
                originalPrice: 4,
                label: 'Yanga Sports Water',
                category: 'Add Ons',
            },
            {
                cost: 10,
                label: 'Joining fee',
                category: 'Joining Fee',
            },
        ],
        total: 31.99,
    },
    monthly: {
        items: [
            {
                cost: 24.99,
                label: 'Ultimate Monthly',
                category: 'Monthly membership Fee',
            },
            {
                cost: 4,
                label: 'Yanga Sports Water',
                category: 'Add Ons',
            },
        ],
        total: 28.99,
    },
};
