import { styled } from '@mui/material/styles';

import { TextInput } from '../TextInput';

export const StyledForm = styled('form')`
    position: relative;
`;

export const StyledTextInput = styled(TextInput)`
    padding-right: 9rem;
`;

export const StyledContainerForButtons = styled('div')`
    position: absolute;
    top: 0;
    right: 1rem;
    display: flex;
    align-items: center;
    height: 100%;
`;

export const StyledClearButton = styled('button')`
    position: absolute;
    right: 3.5rem;
    color: inherit;
    font-size: 1.4rem;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

export const StyledButton = styled('button')`
    position: absolute;
    top: ${({ theme }) => theme.spacing(4)};
    right: ${({ theme }) => theme.spacing(3)};
    padding: ${({ theme }) => theme.spacing(5)};
    background: none;
    border: none;
    cursor: pointer;

    svg {
        cursor: pointer;
    }
`;
