import { SortDirection } from '@tgg/common-types';

export function sortBy<T extends Record<string, any>>(
    arrayToSort: T[],
    sortByValue: keyof T,
    direction: SortDirection,
    sortByFallbackValue?: keyof T,
) {
    const arrayToSortCopy = [...arrayToSort]; // Create a copy of the gyms array

    return arrayToSortCopy.sort((itemA, itemB) => {
        let valueA = itemA[sortByValue];
        let valueB = itemB[sortByValue];

        // Handle objects with undefined values
        if (valueA === undefined && valueB === undefined) {
            return 0; // Keep the order unchanged
        }
        if (valueA === undefined) {
            return 1; // valueA is considered larger (placed at the end)
        }
        if (valueB === undefined) {
            return -1; // valueB is considered larger (placed at the end)
        }

        if (
            valueA === valueB &&
            sortByFallbackValue &&
            sortByFallbackValue !== sortByValue
        ) {
            valueA = itemA[sortByFallbackValue];
            valueB = itemB[sortByFallbackValue];

            return valueA - valueB;
        }

        if (direction === 'down') {
            return valueB - valueA;
        }

        return valueA - valueB;
    });
}
