import { OffPeakTimes } from '@tgg/common-types';

// TODO: Maybe pull these two from services (type used in API)
interface OpeningHours {
    OpeningTime: string;
    ClosingTime: string;
}
interface WeeklyOpeningHours {
    Sunday: OpeningHours;
    Monday: OpeningHours;
    Tuesday: OpeningHours;
    Wednesday: OpeningHours;
    Thursday: OpeningHours;
    Friday: OpeningHours;
    Saturday: OpeningHours;
}

export enum TransportType {
    WALK = 'walk',
    BICYCLE = 'bicycle',
    CAR = 'car',
    BUS = 'bus',
    TUBE = 'tube',
    TRAM = 'tram',
    TRAIN = 'train',
}
interface TransportLink {
    transportType: TransportType;
    // TODO use RichTextContent's type?
    transportInformation: {
        text: string;
        collapsible: boolean;
    };
}

interface Address {
    address1: string;
    address2: string;
    address3: string;
    city: string;
    postcode: string;
}

export interface GymLocationProperties {
    /**
     * The ID attribute for the component.
     */
    id?: string;
    /**
     * The title for the gym location component.
     */
    heading?: string;
    /**
     * The details of the different methods of transport to access the gym.
     */
    transportLinks?: TransportLink[];

    /**
     * The weekly opening hours of the gym.
     */
    openingHours?: WeeklyOpeningHours;

    /**
     * The address of the gym.
     */
    address?: Address;

    /**
     * The latitude of the gym location.
     */
    latitude?: number;

    /**
     * The longitude of the gym location.
     */
    longitude?: number;

    offPeakTimes?: OffPeakTimes[];

    /**
     * The flag that determines whether the Off Peak section should be shown.
     */
    showOffPeakTimes?: boolean;
}
