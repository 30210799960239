import { TableRow } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import {
    TableCompositionCell,
    TableCompositionCellProperties,
} from '../Cell/TableCompositionCell';

export type TableCompositionRowProperties = {
    cells: TableCompositionCellProperties[];
};

export function TableCompositionRow({ cells }: TableCompositionRowProperties) {
    return (
        <TableRow>
            {cells.map(markdown => (
                <TableCompositionCell key={uuidv4()} {...markdown} />
            ))}
        </TableRow>
    );
}
