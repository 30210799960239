/* istanbul ignore file */
import { StyledContainer } from './Container.styled';
import { ContainerProperties } from './Container.types';

/**
 * Use the `Container` component to wrap content that should not be full width on a desktop device.
 */
export function Container({ children }: ContainerProperties) {
    return <StyledContainer>{children}</StyledContainer>;
}
