import { PropositionThumbnailGroupBase } from '../base/PropositionThumbnailGroupBase';
import { PropositionThumbnailGroupCmsProperties } from './PropositionThumbnailGroupCms.types';
import { CmsPage } from '@tgg/common-types';
import {
    dispatchEvent,
    EventKey,
    PropositionThumbnailLinkPayload,
} from '@tgg/services';

/**
 * Use the `PropositionThumbnailGroup` component to display a carousel of Proposition Thumbnails.
 */
export const PropositionThumbnailGroupCms = ({
    id,
    data,
    page,
    ...baseProperties
}: PropositionThumbnailGroupCmsProperties) => {
    const onClick = (
        index: number,
        text: string,
        linkUrl: string,
        linkText: string,
    ) => {
        if (page === CmsPage.HomePage) {
            dispatchEvent<PropositionThumbnailLinkPayload>(
                EventKey.PROPOSITION_THUMBNAIL_LINK,
                {
                    title: text,
                    link_destination: linkUrl,
                    position: `${index + 1}/${data.length}`,
                    link_text: linkText,
                },
            );
        }
    };

    return (
        <PropositionThumbnailGroupBase
            id={id}
            data={data}
            onClick={onClick}
            {...baseProperties}
        />
    );
};

export default PropositionThumbnailGroupCms;
