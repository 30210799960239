import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useRef, useState } from 'react';

import { theme, palette } from '../../theme';
import { ImageStandalone } from '../ImageStandalone';
import { useGallery } from './MediaGallery.notest';
import {
    OverlayedStyledPagination,
    OverlayedStyledSubtitle,
    StyledCarousel,
    StyledIcon,
    StyledNextButton,
    StyledPreviousButton,
    StyledTile,
} from './MediaGallery.styled';
import { Direction, MediaGalleryProperties } from './MediaGallery.types';
import { EventKey, ImageScrollPayload, dispatchEvent } from '@tgg/services';

const INITIAL_IMAGES_MOBILE = 1;
const INITIAL_IMAGES_DESKTOP = 2;

function tilePositionFromIndex(tileIndex: number, stateIndex: number) {
    if (tileIndex === stateIndex) {
        return 'current';
    }
    if (tileIndex === stateIndex - 1) return 'previous';
    if (tileIndex === stateIndex + 1) return 'next';
    if (tileIndex < stateIndex) return 'offscreen-left';

    return 'offscreen-right';
}

/**
 * Use the `MediaGallery` component to display a paginated carousel of images.
 */
export function MediaGallery({ id, tiles, gymName }: MediaGalleryProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const myReference = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);

    const initialImages = isDesktop
        ? INITIAL_IMAGES_DESKTOP
        : INITIAL_IMAGES_MOBILE;
    const [loadAllImages, setLoadAllImages] = useState(false);

    const prevselectedIndex = useRef<number>(0);

    const getDirection = () => {
        return selectedIndex > prevselectedIndex.current
            ? Direction.Right
            : Direction.Left;
    };

    const {
        selectedIndex,
        nextButtonDisabled,
        previousButtonDisabled,
        onTouchStart,
        onTouchMove,
        onTouchEnd,
        onTouchCancel,
        onNextClick,
        onPreviousClick,
    } = useGallery(tiles);

    useEffect(() => {
        /* istanbul ignore next */
        const newWidth = myReference.current?.offsetWidth ?? 0;
        setWidth(isDesktop && tiles.length > 1 ? newWidth - 152 : newWidth);
    }, [isDesktop, myReference, tiles.length]);

    useEffect(() => {
        if (selectedIndex && !loadAllImages) {
            setLoadAllImages(true);
        } else if (loadAllImages) {
            const direction: Direction = getDirection();
            dispatchEvent<ImageScrollPayload>(EventKey.IMAGE_SCROLL, {
                branchName: gymName ?? '',
                imagePosition: `${selectedIndex + 1}/${tiles.length}`,
                scrollDirection: direction,
            });
            prevselectedIndex.current = selectedIndex;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex, loadAllImages]);

    const isInitial = (index: number) =>
        !loadAllImages && index < initialImages;

    return (
        <StyledCarousel
            ref={myReference}
            id={id ?? 'media-gallery'}
            data-testid="carousel"
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onTouchCancel={onTouchCancel}
        >
            {tiles.map((tile, index) => (
                <StyledTile
                    key={
                        typeof tile.image.image === 'string'
                            ? tile.image.image
                            : tile.image.image.name
                    }
                    $position={tilePositionFromIndex(index, selectedIndex)}
                    $addOffset={tiles.length > 1}
                >
                    {isInitial(index) || loadAllImages ? (
                        <ImageStandalone
                            {...tile.image}
                            height={isDesktop ? 544 : 250}
                            width={width}
                        />
                    ) : null}
                    <OverlayedStyledSubtitle
                        variant="h4"
                        component="span"
                        data-testid={`subtitle${index}`}
                    >
                        {tile.subtitle ?? ''}
                    </OverlayedStyledSubtitle>
                </StyledTile>
            ))}
            {isDesktop && tiles.length > 1 && (
                <>
                    <StyledNextButton
                        aria-label="next-image"
                        onClick={onNextClick}
                        disabled={nextButtonDisabled}
                    >
                        <StyledIcon
                            size={32}
                            name="chevronRight"
                            color={
                                nextButtonDisabled
                                    ? palette.grey['200']
                                    : palette.primary.main
                            }
                        />
                    </StyledNextButton>
                    <StyledPreviousButton
                        aria-label="previous-image"
                        onClick={onPreviousClick}
                        disabled={previousButtonDisabled}
                    >
                        <StyledIcon
                            size={32}
                            name="chevronLeft"
                            color={
                                previousButtonDisabled
                                    ? palette.grey['200']
                                    : palette.primary.main
                            }
                        />
                    </StyledPreviousButton>
                </>
            )}
            {tiles.length > 1 && (
                <OverlayedStyledPagination>{`${selectedIndex + 1}/${
                    tiles.length
                }`}</OverlayedStyledPagination>
            )}
        </StyledCarousel>
    );
}

export default MediaGallery;
