import { palette } from '../../theme';
import { Icon } from '../Icon';
import { Tile } from '../Tile';
import {
    StyledArticle,
    StyledBody,
    StyledLink,
    StyledHeading,
    StyledCategoryContainer,
    StyledCategoryItems,
    StyledCategoryBox,
} from './HelpArticlesBox.styled';
import { HelpArticlesBoxProperties } from './HelpArticlesBox.types';
/**
 * HelpArticlesBox is used to display a list of help articles and categories from freshdesk platform.
 */

export function HelpArticlesBox({ articles, tags }: HelpArticlesBoxProperties) {
    return (
        <Tile title="need help with this?">
            <StyledBody data-testid="help-box">
                {articles.map(article => (
                    <StyledLink
                        key={article.id}
                        href={article.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <StyledArticle>
                            <StyledHeading
                                gutterBottom={false}
                                variant="h4"
                                color="#0e0066"
                            >
                                {article.title}
                            </StyledHeading>
                            <Icon
                                name="chevronRight"
                                size={24}
                                color={palette.primary.main}
                            />
                        </StyledArticle>
                    </StyledLink>
                ))}
                <StyledCategoryContainer>
                    <StyledHeading variant="h4">
                        more support topics
                    </StyledHeading>
                    <StyledCategoryItems>
                        {tags.map(tag => (
                            <StyledCategoryBox key={tag.id}>
                                <StyledHeading
                                    gutterBottom={false}
                                    variant="h4"
                                    color="#0e0066"
                                >
                                    {tag.title}
                                </StyledHeading>
                            </StyledCategoryBox>
                        ))}
                    </StyledCategoryItems>
                </StyledCategoryContainer>
            </StyledBody>
        </Tile>
    );
}
